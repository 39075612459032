import React from 'react'
import PropTypes from 'prop-types'
import { RepeatWrapping, TextureLoader } from 'three'
import { useLoader } from 'react-three-fiber'
import { Plane } from '@react-three/drei'

import floorTextureImg from '../../../images/textures/ground.jpg'

const FloorQuadrant = ({
  width,
  height,
  originX,
  originY,
  offsetNumberX,
  offsetNumberY,
}) => {
  const texture = useLoader(TextureLoader, floorTextureImg)
  texture.wrapS = RepeatWrapping
  texture.wrapT = RepeatWrapping
  texture.repeat.set(20, 20)
  texture.offset.set(0, 0)

  return (
    <group
      position={[
        originX + offsetNumberX * width + width / 2,
        originY + offsetNumberY * height - height / 2,
        0,
      ]}
    >
      <Plane
        receiveShadow={true}
        position={[0, 0, -0.02]}
        rotation={[0, 0, 0]}
        args={[width, height]}
      >
        <meshPhongMaterial attach="material" map={texture} />
      </Plane>
      <gridHelper
        position={[0, 0, -0.01]}
        rotation={[Math.PI / 2, 0, 0]}
        args={[width, width, 'white', 'white']}
      />
    </group>
  )
}

FloorQuadrant.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  originX: PropTypes.number,
  originY: PropTypes.number,
  offsetNumberX: PropTypes.number,
  offsetNumberY: PropTypes.number,
}

FloorQuadrant.defaultProps = {
  width: 200,
  height: 200,
  originX: 0,
  originY: 0,
  offsetNumberX: 0,
  offsetNumberY: 0,
}

export default FloorQuadrant
