import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import tw, { styled } from 'twin.macro'

import { useStore } from '../store/index'

const baseStyles = tw`inline-flex items-center text-center px-4 py-1 text-lg font-bold no-underline cursor-pointer pointer-events-auto whitespace-nowrap transition rounded-full duration-200`
const whiteStyles = tw`text-black bg-white hover:bg-brand-light-grey focus:outline-none focus:bg-brand-light-grey`
const blackStyles = tw`text-white bg-black hover:bg-gray-700 hover:text-white focus:outline-none focus:bg-gray-700 focus:text-white`

export const StyledButton = styled.button`
  ${baseStyles}
  ${({ buttonStyle }) => {
    switch (buttonStyle) {
      case 'black':
        return blackStyles
      default:
        return whiteStyles
    }
  }}

  ${({ small }) => {
    if (small) {
      return tw`text-sm`
    }
  }}

  ${({ smallForTablet }) => {
    if (smallForTablet) {
      return `@media (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
      `
    }
  }}

  ${({ smallForTablet }) => {
    if (smallForTablet) {
      return `@media (max-width: 1024px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
      `
    }
  }}
`

const StyledLink = styled(Link)`
  ${baseStyles}
  ${({ buttonStyle }) => {
    switch (buttonStyle) {
      case 'black':
        return blackStyles
      default:
        return whiteStyles
    }
  }}

  ${({ small }) => {
    if (small) {
      return tw`text-sm`
    }
  }}


  ${({ smallForTablet }) => {
    if (smallForTablet) {
      return `@media (max-width: 1024px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }`
    }
  }}
`

const StyledA = styled.a`
  ${baseStyles}
  ${({ buttonStyle }) => {
    switch (buttonStyle) {
      case 'black':
        return blackStyles
      default:
        return whiteStyles
    }
  }}

  ${({ small }) => {
    if (small) {
      return tw`text-sm`
    }
  }}


  ${({ smallForTablet }) => {
    if (smallForTablet) {
      return `@media (max-width: 1024px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }`
    }
  }}
`

const Button = ({
  text,
  buttonStyle,
  small,
  smallForMobile,
  smallForTablet,
  href,
  to,
  playSample,
  onClick,
  ...props
}) => {
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  let buttonMarkup = null

  if (to) {
    buttonMarkup = (
      <StyledLink
        buttonStyle={buttonStyle}
        small={small}
        smallForMobile={smallForMobile}
        smallForTablet={smallForTablet}
        to={to}
        onClick={() => {
          if (playSample) {
            setAudioSampleTriggers('uiClick', true)
          }
        }}
        {...props}
      >
        {text}
      </StyledLink>
    )
  } else if (href) {
    buttonMarkup = (
      <StyledA
        buttonStyle={buttonStyle}
        small={small}
        smallForMobile={smallForMobile}
        smallForTablet={smallForTablet}
        href={href}
        onClick={() => {
          if (playSample) {
            setAudioSampleTriggers('uiClick', true)
          }
        }}
        {...props}
        target="_blank"
      >
        {text}
      </StyledA>
    )
  } else if (onClick) {
    buttonMarkup = (
      <StyledButton
        buttonStyle={buttonStyle}
        small={small}
        smallForMobile={smallForMobile}
        smallForTablet={smallForTablet}
        onClick={() => {
          onClick()
          if (playSample) {
            setAudioSampleTriggers('uiClick', true)
          }
        }}
        {...props}
      >
        {text}
      </StyledButton>
    )
  }

  return buttonMarkup
}

Button.propTypes = {
  text: PropTypes.string,
  buttonStyle: PropTypes.string,
  smallForMobile: PropTypes.bool,
  smallForTablet: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  playSample: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  text: 'Read more',
  buttonStyle: 'white',
  small: false,
  smallForMobile: false,
  smallForTablet: false,
  href: null,
  to: null,
  playSample: false,
  onClick: null,
}

export default Button
