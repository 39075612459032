import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import PanelHeader from './panel-header'
import CircleIconButton from './circle-icon-button'

const StyledPanelContainer = styled.div`
  ${tw`fixed border-white text-white pointer-events-none`}

  ${({ isOpen }) => {
    if (isOpen) {
      return tw`z-20`
    } else {
      return 'z-index: 12;'
    }
  }}

  top: 30px;
  bottom: 60px;
  width: calc(100% - 2rem);

  @media (min-width: 768px) {
    top: 140px;
    bottom: 80px;
    width: 40%;
  }

  ${({ side }) => {
    switch (side) {
      case 'left':
        return tw`left-0 ml-4 md:ml-12 md:border-l-2`
      default:
        return tw`right-0 mr-4 md:mr-12 md:border-r-2`
    }
  }}
`

const StyledPanelContainerInner = styled.div`
  ${tw`overflow-hidden w-full h-full flex flex-col justify-center`}

  ${({ side }) => {
    switch (side) {
      case 'left':
        return tw`md:pl-10`
      default:
        return tw`md:pr-10`
    }
  }}
`

const StyledButtonContainer = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full flex flex-col justify-end md:justify-center pb-2 md:pb-0 md:pl-0 md:pr-0`}
`

const StyledButtonContainerInner = styled.div`
  ${tw`absolute`}

  ${({ side }) => {
    switch (side) {
      case 'left':
        return 'left: 0.5rem;  @media (min-width: 768px) { left: 0; transform: translateX(-50%); }'
      default:
        return 'right: 0.5rem;  @media (min-width: 768px) { right: 0; transform: translateX(50%); }'
    }
  }}
`

const show = {
  x: 'calc(0% - 0px)',
  display: 'block',
  transition: {
    duration: 0.4,
    type: 'tween',
  },
}

const leftVariants = {
  hide: {
    x: 'calc(-100% - 44px)',
    transitionEnd: {
      display: 'none',
    },
    transition: { duration: 0.4, type: 'tween' },
  },
  show,
}

const rightVariants = {
  hide: {
    x: 'calc(100% + 44px)',
    transitionEnd: {
      display: 'none',
    },
    transition: { duration: 0.4, type: 'tween' },
  },
  show,
}

const SidePanel = ({
  heading,
  side,
  icon,
  notificationCount,
  isOpen,
  setIsOpen,
  onClose,
  children,
}) => {
  const [isDesktopWidth, setIsDesktopWidth] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const updateWidth = () => {
    setIsDesktopWidth(window.innerWidth > 768)
  }

  useEffect(() => {
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  // set escape key handler
  useEffect(() => {
    const escFunction = e => {
      if (e.keyCode === 27) {
        setIsOpen(false)
      }
    }
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [setIsOpen])

  return (
    <StyledPanelContainer isOpen={isOpen} side={side}>
      <StyledPanelContainerInner side={side}>
        <motion.div
          tw="w-full pointer-events-auto overflow-hidden relative rounded"
          css={css`
            height: 100%;

            @media (min-width: 768px) {
              max-height: 700px;
            }

            background-color: rgba(0, 0, 0, 0.8);
          `}
          variants={side === 'left' ? leftVariants : rightVariants}
          initial="hide"
          animate={isOpen ? 'show' : 'hide'}
          role="dialog"
          aria-label={heading}
          onAnimationComplete={() => {
            if (!isOpen) {
              onClose()
            }
          }}
        >
          <PanelHeader heading={heading} onClose={toggleOpen} />
          {children}
        </motion.div>
      </StyledPanelContainerInner>
      <StyledButtonContainer side={side}>
        <StyledButtonContainerInner side={side}>
          <CircleIconButton
            icon={icon}
            notificationCount={notificationCount}
            size={isDesktopWidth ? 'large' : 'small'}
            onClick={toggleOpen}
            aria-label={isOpen ? `Close ${heading}` : `Open ${heading}`}
          />
        </StyledButtonContainerInner>
      </StyledButtonContainer>
    </StyledPanelContainer>
  )
}

SidePanel.propTypes = {
  side: PropTypes.string,
  icon: PropTypes.node,
  notificationCount: PropTypes.number,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

SidePanel.defaultProps = {
  side: 'left',
  icon: null,
  notificationCount: 0,
  isOpen: false,
  setIsOpen: null,
  onClose: () => {},
  children: null,
}

export default SidePanel
