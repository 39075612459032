import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import { useStore } from '../store/index'

import SidePanel from './side-panel'
import QuestionIcon from './question-icon'
import Button from './button'
import Marquee from './marquee'

import QuestionsIconImg from '../svgs/questions-icon.svg'

const Questions = ({
  intro,
  outro,
  questionAnsweredText,
  questionNumberMappings,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const activeQuestion = useStore(state => state.activeQuestion)
  const setActiveQuestion = useStore(state => state.setActiveQuestion)

  const questionsCompleted = useStore(state => state.questionsCompleted)
  const setQuestionsCompleted = useStore(state => state.setQuestionsCompleted)

  const [questionsCompletedText, setQuestionsCompletedText] = useState(
    `${questionsCompleted.length} fragments completed\xa0\xa0${questionsCompleted.length} fragments completed\xa0\xa0${questionsCompleted.length} fragments completed`
  )

  const setTriggerWorldZoomOut = useStore(state => state.setTriggerWorldZoomOut)
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  const StyledButton = styled.div`
    ${tw`absolute inset-0 flex justify-center items-center`}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[1])) {
        return '#question-icon-segment-1 { fill: #010202 !important; }'
      }
    }}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[2])) {
        return '#question-icon-segment-2 { fill: #010202 !important; }'
      }
    }}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[3])) {
        return '#question-icon-segment-3 { fill: #010202 !important; }'
      }
    }}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[4])) {
        return '#question-icon-segment-4 { fill: #010202 !important; }'
      }
    }}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[5])) {
        return '#question-icon-segment-5 { fill: #010202 !important; }'
      }
    }}

    ${({ questionsCompleted }) => {
      if (questionsCompleted.includes(questionNumberMappings[6])) {
        return '#question-icon-segment-6 { fill: #010202 !important; }'
      }
    }}
  `
  useEffect(() => {
    if (activeQuestion) {
      setIsOpen(true)
    }
  }, [activeQuestion])

  useEffect(() => {
    if (questionsCompleted.length === 6) {
      setQuestionsCompletedText(
        `all fragments completed\xa0\xa0all fragments completed\xa0\xa0all fragments completed\xa0\xa0all fragments completed\xa0\xa0all fragments completed`
      )
    } else if (questionsCompleted.length === 1) {
      setQuestionsCompletedText(
        `${questionsCompleted.length} fragment completed\xa0\xa0${questionsCompleted.length} fragment completed\xa0\xa0${questionsCompleted.length} fragment completed`
      )
    } else {
      setQuestionsCompletedText(
        `${questionsCompleted.length} fragments completed\xa0\xa0${questionsCompleted.length} fragments completed\xa0\xa0${questionsCompleted.length} fragments completed`
      )
    }
  }, [questionsCompleted])

  useEffect(() => {
    setAudioSampleTriggers(isOpen ? 'uiOpen' : 'uiClose', true)
  }, [isOpen, setAudioSampleTriggers])

  const submitAnswer = (questionId, answerId) => {
    const url =
      'https://cms.thisplaceofmine.org/actions/submissions/default/save-submission'

    const options = {
      method: 'POST',
      body: JSON.stringify({
        questionId,
        answerId,
      }),
    }

    fetch(url, options)
      .then(response => {
        if (response.status === 200) {
          setAudioSampleTriggers('uiQuestionComplete', true)
          setQuestionsCompleted(activeQuestion.id)
        }
      })
      .catch(function (err) {
        console.log('Fetch Error', err)
      })
  }

  const renderSwitch = () => {
    if (activeQuestion) {
      if (questionsCompleted.includes(activeQuestion.id)) {
        // question active and completed
        return (
          <div tw="flex h-full flex-col justify-center">
            <div
              tw="text-sm md:text-lg lg:text-2xl text-brand-dark-grey text-center mb-8"
              css={css`
                @media (max-height: 900px) and (min-width: 768px) {
                  ${tw`text-lg!`}
                }
              `}
            >
              <div tw="mb-4">
                <QuestionIcon isCompleted={true} />
              </div>
              <p>Completed the fragment</p>
            </div>
            <div
              tw="text-base md:text-lg lg:text-3xl text-center"
              css={css`
                @media (max-height: 900px) and (min-width: 768px) {
                  ${tw`text-base!`}
                }
              `}
              dangerouslySetInnerHTML={{
                __html:
                  questionsCompleted.length <
                  Object.keys(questionNumberMappings).length
                    ? questionAnsweredText
                    : outro,
              }}
            ></div>
            <div tw="w-full mt-8 flex justify-center">
              <Button
                onClick={() => {
                  setTriggerWorldZoomOut(true)
                  setIsOpen(false)
                }}
                smallForTablet={true}
                text="keep exploring"
              />
            </div>
          </div>
        )
      } else {
        // question active and not completed
        return (
          <div tw="flex h-full flex-col justify-around">
            <div
              tw="text-base md:text-xl lg:text-3xl text-center"
              css={css`
                @media (max-height: 900px) and (min-width: 768px) {
                  ${tw`text-base!`}
                }
              `}
              dangerouslySetInnerHTML={{
                __html: activeQuestion.title,
              }}
            ></div>
            <div tw="flex flex-col w-full items-center">
              {activeQuestion.answers.map(answer => (
                <Button
                  key={answer.id}
                  tw="mb-4"
                  text={answer.answer}
                  smallForTablet={true}
                  onClick={() => {
                    submitAnswer(activeQuestion.remoteId, answer.id)
                  }}
                ></Button>
              ))}
            </div>
          </div>
        )
      }
    } else {
      // question not active
      return (
        <div tw="flex h-full flex-col justify-between">
          <div
            tw="text-sm md:text-lg lg:text-2xl"
            css={css`
              @media (max-height: 900px) and (min-width: 768px) {
                ${tw`text-base!`}
              }
            `}
            dangerouslySetInnerHTML={{
              __html:
                questionsCompleted.length <
                Object.keys(questionNumberMappings).length
                  ? intro
                  : outro,
            }}
          ></div>
          <div tw="flex justify-between -mx-1 xl:-mx-2">
            {Array.from({ length: 6 }, (_, i) => i + 1).map(i => (
              <QuestionIcon
                key={`q${i}`}
                number={i}
                isCompleted={questionsCompleted.includes(
                  questionNumberMappings[i]
                )}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  return (
    <SidePanel
      heading="TPOM future fragments"
      side="right"
      icon={
        <StyledButton questionsCompleted={questionsCompleted}>
          <QuestionsIconImg></QuestionsIconImg>
        </StyledButton>
      }
      isOpen={isOpen}
      setIsOpen={isOpen => {
        // we want to zoom out if closing and we're looking at a question
        if (!isOpen && activeQuestion) {
          setTriggerWorldZoomOut(true)
        }
        setIsOpen(isOpen)
      }}
      onClose={() => setActiveQuestion(null)}
    >
      <div
        tw="flex flex-col justify-between"
        css={css`
          margin-top: 56px;
          height: calc(100% - 56px);
          .scrollbar-track {
            ${tw`bg-black!`}
            width: 12px !important;
          }
          .scrollbar-thumb {
            width: 12px !important;
            ${tw`bg-white! rounded-full!`}
          }
        `}
      >
        <div tw="h-full flex flex-col justify-between pr-12 pl-12 pt-8">
          {renderSwitch()}
        </div>
        <div tw="pt-8 pb-4 overflow-hidden">
          <Marquee size="medium">{questionsCompletedText}</Marquee>
        </div>
      </div>
    </SidePanel>
  )
}

Questions.propTypes = {
  intro: PropTypes.string,
  outro: PropTypes.string,
  questionAnsweredText: PropTypes.string,
}

Questions.defaultProps = {
  intro: '',
  outro: '',
  questionAnsweredText: '',
}

export default Questions
