import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DoubleSide } from 'three'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import { useFrame, useLoader } from 'react-three-fiber'

const Brandmark = ({ image, focusOn, ...props }) => {
  const ref = useRef()
  const pivotRef = useRef()
  const meshRef = useRef()

  const rotationSpeed = 0.5

  useFrame((state, delta) => {
    if (ref.current) {
      pivotRef.current.rotation.z += rotationSpeed * delta
    }
  })

  const { paths } = useLoader(SVGLoader, image.url)
  const shapes = useMemo(() => paths.flatMap(p => p.toShapes(true)), [paths])

  const scaleFactor = 0.032

  // this nicely rotates around pivot due to pivot group
  return (
    <group
      ref={ref}
      rotation={[0, -Math.PI / 8, 0]}
      position-x={-(scaleFactor * image.width) / 2}
      position-y={3}
      position-z={(scaleFactor * image.height) / 2}
      scale={[scaleFactor, scaleFactor, scaleFactor]}
      {...props}
    >
      <group ref={pivotRef}>
        {shapes.map(shape => (
          <mesh
            ref={meshRef}
            key={shape.uuid}
            position={[-image.width / 2, -image.height / 2]}
          >
            <shapeBufferGeometry attach="geometry" args={[shape]} />
            <meshBasicMaterial
              attach="material"
              color="blue"
              side={DoubleSide}
            />
          </mesh>
        ))}
      </group>
    </group>
  )
}

Brandmark.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    focusOn: PropTypes.func,
  }),
}

export default Brandmark
