import React, { useEffect, useRef } from 'react'

import 'twin.macro'

import { useStore } from '../store/index'

const Stats = () => {
  let startTimeMsRef = useRef(null)
  const setTimeInApp = useStore(state => state.setTimeInApp)

  useEffect(() => {
    const startTimeMs = new Date().getTime()
    startTimeMsRef.current = startTimeMs

    const interval = setInterval(() => {
      const currentTimeMs = new Date().getTime()
      const timeInAppMs = currentTimeMs - startTimeMsRef.current
      setTimeInApp(Math.floor(timeInAppMs / 1000))
    }, 1000)

    return () => clearInterval(interval)
  }, [setTimeInApp])

  return <></>
}

export default Stats
