import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  AnimationMixer,
  RepeatWrapping,
  TextureLoader,
  MeshBasicMaterial,
} from 'three'
import { useGLTF } from '../../../../../hooks/useGLTF'
import { useFrame, useThree, useLoader } from 'react-three-fiber'
import gsap from 'gsap'

import { useStore } from '../../../../../store/index'

import noiseTextureImg from '../../../../../images/textures/noise.png'

const Question = ({
  id,
  question,
  questionNumberMappings,
  focusOn,
  ...props
}) => {
  // we need to establish which question number this is to choose the model
  const getKeyByValue = (object, value) =>
    Object.keys(object).find(key => object[key] === value)

  const questionNumber = getKeyByValue(questionNumberMappings, id)
    ? getKeyByValue(questionNumberMappings, id)
    : 1

  const questionsCompleted = useStore(state => state.questionsCompleted)

  const model = useGLTF(`/models/Missing-Artefact-0${questionNumber}.gltf`)
  const { gl } = useThree()

  const [isInitialized, setIsInitialized] = useState(false)
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false)
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)

  let animatorRef = useRef()
  let missingMeshRef = useRef()
  let revealedMeshRef = useRef()
  let glowMeshRef = useRef()

  const tweenValuesRef = useRef({
    glowOpacity: 0,
    missingOpacity: 1,
    revealedOpacity: 0,
  })

  const noiseTexture = useLoader(TextureLoader, noiseTextureImg)
  noiseTexture.wrapS = RepeatWrapping
  noiseTexture.wrapT = RepeatWrapping

  const noiseMaterial = new MeshBasicMaterial({
    map: noiseTexture,
  })
  //texture.repeat.set(20, 20)
  //texture.offset.set(0, 0)

  useEffect(() => {
    if (!isInitialized) {
      model.scene.traverse(node => {
        if (node.isMesh) {
          node.castShadow = !(node.name === 'Glow_Artefact')

          if (node.material && node.material.map) {
            gl.initTexture(node.material.map)
          }

          if (node.material && node.material.emissiveMap) {
            gl.initTexture(node.material.emissiveMap)
          }
        }

        if (node.name === 'Missing_Artefact') {
          missingMeshRef.current = node
          missingMeshRef.current.material = noiseMaterial
          missingMeshRef.current.material.transparent = true
          missingMeshRef.current.material.opacity = 1
          missingMeshRef.current.material.needsUpdate = true
        }

        if (node.name === 'Revealed_Artefact') {
          revealedMeshRef.current = node
          revealedMeshRef.current.material.transparent = true
          revealedMeshRef.current.material.opacity = 0
          revealedMeshRef.current.material.needsUpdate = true
        }

        if (node.name === 'Glow_Artefact') {
          glowMeshRef.current = node
          glowMeshRef.current.material.transparent = true
          glowMeshRef.current.material.opacity = 0
          glowMeshRef.current.material.needsUpdate = true
        }
      })

      if (model.animations.length) {
        if (!animatorRef.current) {
          animatorRef.current = new AnimationMixer(model.scene)

          model.animations.forEach(clip => {
            const action = animatorRef.current.clipAction(clip)
            action.play()
          })
        }
      }
      setIsInitialized(true)
    }
  }, [gl, isInitialized, model, noiseMaterial])

  useEffect(() => {
    const playCompletedAnimation = () => {
      setIsAnimationPlaying(true)
      gsap.to(tweenValuesRef.current, {
        glowOpacity: 1,
        missingOpacity: 0,
        revealedOpacity: 1,
        onComplete() {
          setIsAnimationPlaying(false)
          setIsAnimationComplete(true)
        },
      })
    }

    if (questionsCompleted.includes(id) && !isAnimationComplete) {
      playCompletedAnimation()
    }
  }, [id, isAnimationComplete, model.scene, questionsCompleted])

  const noiseDensity = 26
  useFrame((state, delta) => {
    if (animatorRef.current) {
      animatorRef.current.update(delta)
    }
    if (missingMeshRef.current) {
      missingMeshRef.current.material.map.offset.x =
        (missingMeshRef.current.material.map.offset.x +=
          (Math.random() * noiseDensity) / noiseDensity) % 1
      missingMeshRef.current.material.map.offset.y =
        (missingMeshRef.current.material.map.offset.y +=
          (Math.random() * noiseDensity) / noiseDensity) % 1
    }

    if (isAnimationPlaying) {
      missingMeshRef.current.material.opacity =
        tweenValuesRef.current.missingOpacity
      missingMeshRef.current.material.needsUpdate = true
      revealedMeshRef.current.material.opacity =
        tweenValuesRef.current.revealedOpacity
      revealedMeshRef.current.material.needsUpdate = true
      glowMeshRef.current.material.opacity = tweenValuesRef.current.glowOpacity
      glowMeshRef.current.material.needsUpdate = true
    }
  })

  const onClick = e => {
    e.stopPropagation()

    if (focusOn) {
      focusOn(e.eventObject.parent, null, question)
    }
  }

  return model ? (
    <primitive
      object={model.scene}
      castShadow={true}
      scale={[0.005, 0.005, 0.005]}
      rotation={[0, 0, 0]}
      onPointerDown={onClick}
      dispose={null}
      {...props}
    />
  ) : null
}

Question.propTypes = {}

export default Question
