import React from 'react'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import TriangleImg from '../svgs/triangle.svg'

const Questions = ({ number, isCompleted }) => {
  const StyledWrapper = styled.div`
    ${tw`relative px-1 xl:px-2`}

    ${({ isCompleted }) => {
      if (isCompleted) {
        return tw`text-black`
      } else {
        return tw`text-white`
      }
    }}

    svg {
      ${tw`w-full`}

      g {
        ${({ isCompleted }) => {
          if (!isCompleted) {
            return 'fill:none;'
          }
        }}
      }
    }
  `

  return (
    <StyledWrapper isCompleted={isCompleted}>
      <TriangleImg tw="relative z-10 mx-auto" />
      <div tw="absolute inset-0 flex justify-center items-center z-20">
        <span
          tw="relative text-sm md:text-xs lg:text-base"
          css={css`
            top: 2px;
            @media (min-width: 1024px) {
              top: 6px;
            }
          `}
        >
          {number}
        </span>
      </div>
    </StyledWrapper>
  )
}

export default Questions
