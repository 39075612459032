import React from 'react'

import QuadrantLayout from '../quadrant-layout'
import FloorQuadrant from './quadrant'

const Floor = () => {
  return (
    <QuadrantLayout width={200} height={200}>
      <FloorQuadrant />
      <FloorQuadrant />
      <FloorQuadrant />
      <FloorQuadrant />
    </QuadrantLayout>
  )
}

export default Floor
