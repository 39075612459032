import {
  RGBFormat,
  LinearFilter,
} from '../../../node_modules/three/src/constants.js'
import { Texture } from '../../../node_modules/three/src/textures/Texture.js'

function VideoTexture(
  video,
  mapping,
  wrapS,
  wrapT,
  magFilter,
  minFilter,
  format,
  type,
  anisotropy
) {
  Texture.call(
    this,
    video,
    mapping,
    wrapS,
    wrapT,
    magFilter,
    minFilter,
    format,
    type,
    anisotropy
  )

  this.format = format !== undefined ? format : RGBFormat

  this.minFilter = minFilter !== undefined ? minFilter : LinearFilter
  this.magFilter = magFilter !== undefined ? magFilter : LinearFilter

  this.generateMipmaps = false

  const scope = this

  function updateVideo() {
    scope.needsUpdate = true
    video.requestVideoFrameCallback(updateVideo)
  }

  if ('requestVideoFrameCallback' in video) {
    video.requestVideoFrameCallback(updateVideo)
  }
}

VideoTexture.prototype = Object.assign(Object.create(Texture.prototype), {
  constructor: VideoTexture,

  clone: function () {
    return new this.constructor(this.image).copy(this)
  },

  isVideoTexture: true,

  /* this has been modified so as it not upload texture data to gpu if paused */
  update: function () {
    const video = this.image
    const paused = video.paused

    // Don't transfer textures from paused videos.
    if (paused && this.wasPaused) return

    const hasVideoFrameCallback = 'requestVideoFrameCallback' in video

    if (
      hasVideoFrameCallback === false &&
      video.readyState >= video.HAVE_CURRENT_DATA
    ) {
      if (paused) {
        this.wasPaused = true
      } else if (this.wasPaused) {
        this.wasPaused = false
      }

      this.needsUpdate = true
    }
  },
})

export { VideoTexture }
