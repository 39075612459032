import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Pizzicato from 'pizzicato'
import gsap from 'gsap'

import 'twin.macro'

import { useStore } from '../../store/index'

import { checkIfiOS } from '../../utilities/browser'

const AudioAmbient = () => {
  let soundInitializedRef = useRef(false)
  let audioPlayerRef = useRef(null)
  const isMuted = useStore(state => state.isMuted)
  const isAmbientAudioDucked = useStore(state => state.isAmbientAudioDucked)

  const tweenValuesRef = useRef({
    volume: 0,
  })

  useEffect(() => {
    if (!checkIfiOS()) {
      audioPlayerRef.current = new Pizzicato.Sound(
        {
          source: 'file',
          options: { path: '/audio/ambient.mp3', loop: true },
        },
        () => {
          soundInitializedRef.current = true
        }
      )
    }
  }, [])

  useEffect(() => {
    if (!checkIfiOS()) {
      const play = () => {
        gsap.killTweensOf(tweenValuesRef.current)
        audioPlayerRef.current.play()
        gsap.to(tweenValuesRef.current, {
          volume: isAmbientAudioDucked ? 0.1 : 1,
          duration: 1,
          onUpdate() {
            audioPlayerRef.current.volume = tweenValuesRef.current.volume
          },
        })
      }

      const pause = () => {
        gsap.killTweensOf(tweenValuesRef.current)
        gsap.to(tweenValuesRef.current, {
          volume: 0,
          duration: 1,
          onUpdate() {
            audioPlayerRef.current.volume = tweenValuesRef.current.volume
          },
          onComplete() {
            if (isMuted) {
              audioPlayerRef.current.pause()
            }
          },
        })
      }

      if (audioPlayerRef.current && soundInitializedRef.current) {
        if (isMuted) {
          pause()
        } else {
          play()
        }
      }
    }
  }, [isAmbientAudioDucked, isMuted])

  return <></>
}

AudioAmbient.propTypes = {
  isPlaying: PropTypes.bool,
}

AudioAmbient.defaultProps = {
  isPlaying: false,
}

export default AudioAmbient
