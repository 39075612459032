import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useProgress } from '@react-three/drei'
import gsap from 'gsap'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import LogoImg from '../svgs/logo-intro.svg'

import BrandmarkCycle from '../components/brandmark-cycle'
import ProgressRing from '../components/progress-ring'
import CookieForm from '../components/cookie-form'
import Modal from '../components/modal'
import Text from '../components/text'

const StyledIntroContainer = styled.div`
  ${tw`fixed left-0 top-0 flex justify-center items-center w-full h-full z-70 opacity-0 transition-opacity duration-1250 bg-black text-white px-4`}

  ${({ disablePointerEvents }) =>
    disablePointerEvents ? tw`pointer-events-none` : tw`pointer-events-auto`}
`

const StyledAnimationContainer = styled.div`
  ${tw`w-full relative`}
  max-width: 250px;

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 1024px) {
    max-width: none;
  }

  svg {
    ${tw`h-auto`}
    width: 45%;
  }

  svg.logo {
    width: 67%;
  }

  svg.progress-ring {
    ${tw`w-full h-auto`}
  }
`

const Intro = ({
  body,
  cookiePolicy,
  style,
  setShowIntro,
  disablePointerEvents,
}) => {
  const { progress } = useProgress()

  const [maxProgress, setMaxProgress] = useState(0)
  const [brandmarksPlaying, setBrandmarksPlaying] = useState(true)
  const [showTerms, setShowTerms] = useState(false)

  const overlayRef = useRef()
  const brandmarksContainerRef = useRef()
  const logoImgContainerRef = useRef()
  const progressRingRef = useRef()
  const textContainerRef = useRef()

  const handleOpenTerms = () => {
    setShowTerms(true)
  }

  const handleCloseTerms = () => {
    setShowTerms(false)
  }

  // ensure the circle doesn't go backwards!
  useEffect(() => {
    if (progress > maxProgress) {
      setMaxProgress(progress)
    }
  }, [maxProgress, progress])

  // when loaded, start the logo animation
  useEffect(() => {
    if (progress === 100) {
      startLogoAnimation()
    }
  }, [progress])

  const startLogoAnimation = () => {
    const tl = gsap.timeline()
    tl.add(() => {
      setBrandmarksPlaying(false)
    }, '+=0.8')
    tl.set(brandmarksContainerRef.current, { display: 'none' }, '+=0.1')
    tl.set(brandmarksContainerRef.current, { display: 'flex' }, '+=0.2')
    tl.set(brandmarksContainerRef.current, { display: 'none' }, '+=0.3')
    tl.set(logoImgContainerRef.current, { display: 'flex' })
    tl.set(logoImgContainerRef.current, { display: 'none' }, '+=0.2')
    tl.set(progressRingRef.current, { display: 'none' })
    tl.set(logoImgContainerRef.current, { display: 'flex' }, '+=0.2')
    tl.set(progressRingRef.current, { display: 'block' })
    tl.set(logoImgContainerRef.current, { display: 'none' }, '+=0.2')
    tl.set(logoImgContainerRef.current, { display: 'flex' }, '+=0.2')
    tl.set(progressRingRef.current, { autoAlpha: 0 })

    // need different animations for different screen sizes
    let logoScale = 0.7
    let logoMove = -200

    if (window.innerHeight < 900 && window.innerWidth >= 768) {
      logoMove = -140
    } else if (window.innerWidth < 768) {
      logoScale = 1
      logoMove = -160
    } else if (window.innerWidth < 1024) {
      logoMove = -210
    }

    tl.to(
      logoImgContainerRef.current,
      { scale: logoScale, y: logoMove, duration: 0.7 },
      '+=0.2'
    )
    tl.set(textContainerRef.current, { visibility: 'visible' }, '+=0')
    tl.set(textContainerRef.current, { visibility: 'hidden' }, '+=0.2')
    tl.set(textContainerRef.current, { visibility: 'visible' }, '+=0.16')
  }

  const onClickEnter = () => {
    const tl = gsap.timeline()
    tl.to(logoImgContainerRef.current, {
      scale: 3,
      autoAlpha: 0,
      duration: 1,
    })
    tl.to(
      overlayRef.current,
      { display: 'block', autoAlpha: 1, duration: 0.4 },
      '-=0.5'
    )
    tl.add(() => {
      setShowIntro(false)
    }, '-=1')
  }

  return (
    <>
      <Modal isOpen={showTerms} handleCloseModal={handleCloseTerms}>
        <Text heading="Cookie Policy" body={cookiePolicy} />
      </Modal>
      <StyledIntroContainer
        style={style}
        disablePointerEvents={disablePointerEvents}
      >
        <div
          tw="absolute inset-0 bg-white hidden opacity-0"
          ref={overlayRef}
        ></div>
        <div tw="relative w-full max-w-lg mx-auto flex flex-col justify-center items-center px-4 md:px-0">
          <StyledAnimationContainer>
            <div ref={progressRingRef}>
              <ProgressRing radius={60} stroke={1} progress={maxProgress} />
            </div>
            <div
              tw="absolute inset-0 flex justify-center items-center"
              ref={brandmarksContainerRef}
            >
              <BrandmarkCycle isPlaying={brandmarksPlaying} />
            </div>
            <div
              tw="absolute inset-0 flex mx-8 md:mx-0 flex-col justify-center items-center hidden"
              ref={logoImgContainerRef}
            >
              <LogoImg className="logo" />
            </div>
          </StyledAnimationContainer>
          <div
            ref={textContainerRef}
            tw="invisible absolute"
            css={css`
              top: 40px;
              @media (min-width: 768px) {
                top: 170px;
              }

              @media (max-height: 900px) and (min-width: 768px) {
                top: 220px !important;
              }
            `}
          >
            <div
              tw="text-base md:text-lg lg:text-2xl text-center mt-4"
              dangerouslySetInnerHTML={{
                __html: body,
              }}
              css={css`
                @media (max-height: 900px) and (min-width: 768px) {
                  ${tw`text-base!`}
                }
              `}
            ></div>
            <CookieForm openTerms={handleOpenTerms} onAccept={onClickEnter} />
          </div>
        </div>
      </StyledIntroContainer>
    </>
  )
}

Intro.propTypes = {
  body: PropTypes.string,
  cookiePolicy: PropTypes.string,
  style: PropTypes.object,
  setShowIntro: PropTypes.bool,
  disablePointerEvents: PropTypes.bool,
}

Intro.defaultProps = {
  body: '',
  cookiePolicy: '',
  style: {},
  setShowIntro: false,
  disablePointerEvents: false,
}

export default Intro
