import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Scrollbar from 'smooth-scrollbar'

import { useStore } from '../store/index'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import BrandmarkCycle from './brandmark-cycle'

const StyledHeader = styled.header`
  ${tw`w-full border-black border-t-2 border-b-2 py-1 cursor-pointer transition duration-200 hover:bg-brand-light-grey focus:bg-brand-light-grey focus:outline-none`}

  ${({ isOpen }) => {
    if (isOpen)
      return tw`bg-brand-blue focus:bg-brand-blue active:bg-brand-blue text-white focus:text-white active:text-white border-brand-blue hover:bg-brand-blue py-2`
    else {
      return ''
    }
  }}
`

const variants = {
  hide: {
    y: 'calc(100% - 40px)',
    transition: { duration: 0.4, type: 'tween' },
  },
  hideCompletely: {
    y: '100%',
    transition: { duration: 0.4, type: 'tween' },
  },
  show: {
    y: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
    },
  },
}

const ArtefactList = ({ intro, artefacts }) => {
  const [isOpen, setIsOpen] = useState(false)

  const setArtefactToPanToID = useStore(state => state.setArtefactToPanToID)
  const activeQuestion = useStore(state => state.activeQuestion)
  const focussedObjectUrl = useStore(state => state.focussedObjectUrl)
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const panTo = (id, url) => {
    setIsOpen(false)
    setArtefactToPanToID({
      id,
      url,
    })
  }

  // set escape key handler
  useEffect(() => {
    const escFunction = e => {
      if (e.keyCode === 27) {
        setIsOpen(false)
      }
    }
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [setIsOpen])

  useEffect(() => {
    setAudioSampleTriggers(isOpen ? 'uiOpen' : 'uiClose', true)
  }, [isOpen, setAudioSampleTriggers])

  const scrollableElRef = useCallback(node => {
    if (node !== null) {
      Scrollbar.init(node)
    } else {
      Scrollbar.destroyAll()
    }
  }, [])

  return (
    <motion.div
      tw="fixed z-20 w-full inset-x-0 bg-white text-black text-lg lg:text-xl"
      variants={variants}
      initial="hide"
      animate={
        isOpen
          ? 'show'
          : activeQuestion || focussedObjectUrl
          ? 'hideCompletely'
          : 'hide'
      }
      aria-modal="true"
      role="dialog"
      css={css`
        bottom: 0px;
      `}
    >
      <StyledHeader
        tabIndex="0"
        isOpen={isOpen}
        onClick={toggleOpen}
        aria-label={isOpen ? `Close artefact list` : `Open artefact list`}
        onKeyDown={e => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            toggleOpen()
          }
        }}
      >
        <div tw="container">
          <div tw="flex flex-row -mx-8">
            <div tw="w-full px-8 flex justify-between items-center">
              <div>
                <FontAwesomeIcon tw="mr-2" icon={['far', 'arrow-down']} />
                list of artefacts
              </div>
              {isOpen && <FontAwesomeIcon icon={['fal', 'times']} />}
            </div>
          </div>
        </div>
      </StyledHeader>
      <div
        ref={scrollableElRef}
        css={css`
          height: 70vh;
          .scrollbar-track {
            ${tw`bg-transparent!`}
            width: 12px !important;
          }
          .scrollbar-thumb {
            ${tw`bg-white! rounded-full!`}
            width: 12px !important;
          }
        `}
      >
        <div
          css={css`
            height: auto;
          `}
        >
          {intro ? (
            <div tw="py-8 md:py-12 lg:py-16 bg-brand-light-grey border-b-2 border-black">
              <div tw="container">
                <div tw="flex flex-wrap justify-between items-center -mx-8">
                  <div tw="w-full lg:w-7/12 px-8">
                    <div
                      tw="md:pl-6 max-w-xl lg:text-2xl"
                      dangerouslySetInnerHTML={{
                        __html: intro,
                      }}
                    ></div>
                  </div>
                  <div tw="w-full lg:w-5/12 px-8 hidden lg:block">
                    <div
                      tw="flex justify-center items-center w-16 h-16 xl:w-32 xl:h-32"
                      css={css`
                        svg {
                          ${tw`w-full h-auto relative`}
                          bottom: 10px;
                        }
                        path,
                        polygon,
                        rect,
                        circle {
                          fill: black;
                        }
                      `}
                    >
                      <BrandmarkCycle duration={400} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {artefacts.map(item => (
            <div
              tw="py-1 transition duration-200 hover:bg-black hover:text-white focus:outline-none focus:bg-black focus:text-white cursor-pointer border-black border-b-2"
              onClick={() => panTo(item.id, item.uri)}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  panTo(item.id, item.uri)
                }
              }}
              key={item.id}
              tabIndex={isOpen ? 0 : -1}
              role="button"
              aria-label={`View ${item.title}, an artwork by ${item.artistName}`}
            >
              <div tw="container">
                <div tw="flex flex-col md:flex-row -mx-8 md:py-1">
                  <div tw="w-full md:w-1/3 px-8">
                    <div tw="md:pl-6">{item.title}</div>
                  </div>
                  <div tw="w-full md:w-1/4 px-8">{item.artistName}</div>
                  <div tw="w-full md:w-1/6 px-8">{item.artistType}</div>
                  <div tw="w-full md:w-1/4 px-8 flex flex-row justify-between items-center">
                    <div>{item.artworkType}</div>
                    <div tw="text-white">
                      <FontAwesomeIcon icon={['far', 'arrow-right']} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  )
}

ArtefactList.propTypes = {
  intro: PropTypes.string,
}

ArtefactList.defaultProps = {
  intro: '',
}

export default ArtefactList
