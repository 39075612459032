import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { AnimationMixer, FrontSide, BackSide } from 'three'
import { useGLTF } from '../../../../../hooks/useGLTF'
import { useFrame, useThree } from 'react-three-fiber'
import gsap from 'gsap'

import { useStore } from '../../../../../store/index'

import {
  calculateTitleDimensions,
  createTitleTexture,
} from '../../../../../utilities/textures'

const Artefact3D = ({
  id,
  object,
  title,
  scaleFactor,
  verticalOffset,
  rotation,
  uri,
  focusOn,
  wireframe,
  ...props
}) => {
  const model = useGLTF(object.url)
  const { gl, camera } = useThree()
  const ref = useRef()
  const animatorRef = useRef()
  const titleCylinderFrontRef = useRef()
  const titleTextureMaterialFrontRef = useRef()
  const titleCylinderBackRef = useRef()
  const titleTextureMaterialBackRef = useRef()
  const tweenValuesRef = useRef({
    titleOpacity: 0,
  })

  const [titleTexture, setTitleTexture] = useState(null)

  const focussedObjectUrl = useStore(state => state.focussedObjectUrl)

  // check for pan change
  useStore.subscribe(
    artefactToPanToID => {
      if (artefactToPanToID && artefactToPanToID.id === id) {
        focusOn(ref.current.parent, uri)
      }
    },
    state => state.artefactToPanToID
  )

  const aspectRatio = 16 / 9

  const width = 2 * aspectRatio
  const height = 2

  const {
    titleCylinderRadius,
    titleCylinderHeight,
    titleCylinderTextureWidth,
    titleCylinderTextureHeight,
  } = calculateTitleDimensions(width, height)

  useEffect(() => {
    if (title) {
      setTitleTexture(
        createTitleTexture(
          title,
          titleCylinderTextureWidth,
          titleCylinderTextureHeight
        )
      )
    }
  }, [title, titleCylinderTextureHeight, titleCylinderTextureWidth])

  // position cylinder in middle of bounding box when available
  /*
  useEffect(() => {
    if (titleTexture && titleCylinderRef.current) {
      console.log('xxx')
      const bbox = new Box3().setFromObject(ref.current)
      const center = bbox.getCenter()
      const objectWorldPos = ref.current.getWorldPosition()
      console.log('object world pos', ref.current.getWorldPosition())
      console.log('bboxCenter', center)
      titleCylinderRef.current.position.x = center.x - objectWorldPos.x
      //titleCylinderRef.current.position.y = center.y - objectWorldPos.y
      //titleCylinderRef.current.position.z = center.z - objectWorldPos.z

      console.log('cylinderNewPos', titleCylinderRef.current.position)
    }
  }, [titleTexture])
  */

  useEffect(() => {
    if (focussedObjectUrl === uri) {
      //const bbox = new Box3().setFromObject(ref.current)
      //  titleCylinderRef.current.position.x = bbox.max.x - bbox.min.x
      //  titleCylinderRef.current.position.y = bbox.max.y - bbox.min.y
      //  titleCylinderRef.current.position.z = bbox.max.z - bbox.min.z
      //console.log(ref.current.getWorldPosition())
      //console.log(focussedObjectLookAtPos)
      //titleCylinderRef.current.position.set(focussedObjectLookAtPos)
      //console.log('cameracamera.position)
      //titleCylinderRef.current.position.x = camera.position.x
      //titleCylinderRef.current.position.z = camera.position.z

      gsap.to(tweenValuesRef.current, {
        titleOpacity: 1,
        duration: 1,
      })
    } else {
      gsap.to(tweenValuesRef.current, {
        titleOpacity: 0,
        duration: 1,
      })
    }
  }, [camera.position.x, camera.position.z, focussedObjectUrl, uri])

  useEffect(() => {
    model.scene.traverse(node => {
      if (node.isMesh) {
        node.castShadow = true

        if (wireframe && node.isMesh) {
          node.material.wireframe = true
        } else {
          if (node.material && node.material.map) {
            gl.initTexture(node.material.map)
          }

          if (node.material && node.material.emissiveMap) {
            gl.initTexture(node.material.emissiveMap)
          }
        }
      }
    })

    if (model.animations.length) {
      if (!animatorRef.current) {
        animatorRef.current = new AnimationMixer(model.scene)

        model.animations.forEach(clip => {
          const action = animatorRef.current.clipAction(clip)
          action.play()
        })
      }
    }
  }, [gl, model, wireframe])

  const rotationSpeed = 0.5

  useFrame((state, delta) => {
    if (animatorRef.current) {
      animatorRef.current.update(delta)
    }

    if (titleCylinderFrontRef.current && titleCylinderBackRef.current) {
      titleCylinderFrontRef.current.rotation.y -= rotationSpeed * delta
      titleCylinderBackRef.current.rotation.y -= rotationSpeed * delta
    }

    if (
      titleTextureMaterialFrontRef.current &&
      titleTextureMaterialBackRef.current
    ) {
      titleTextureMaterialFrontRef.current.opacity =
        tweenValuesRef.current.titleOpacity
      titleTextureMaterialBackRef.current.opacity =
        tweenValuesRef.current.titleOpacity
    }
  })

  const degreesToRadians = degrees => {
    return degrees * (Math.PI / 180)
  }

  return model ? (
    <>
      {title && titleTexture ? (
        <>
          <mesh
            ref={titleCylinderFrontRef}
            receiveShadow={false}
            position-y={titleCylinderHeight / 3}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={FrontSide}
              map={titleTexture}
              transparent={true}
              ref={titleTextureMaterialFrontRef}
              opacity={0}
              depthTest={false}
            />
          </mesh>
          <mesh
            ref={titleCylinderBackRef}
            receiveShadow={false}
            position-y={titleCylinderHeight / 3}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={BackSide}
              map={titleTexture}
              transparent={true}
              ref={titleTextureMaterialBackRef}
              opacity={0}
            />
          </mesh>
        </>
      ) : null}
      <primitive
        object={model.scene}
        castShadow={true}
        position-y={verticalOffset}
        scale={[scaleFactor, scaleFactor, scaleFactor]}
        rotation={[0, degreesToRadians(rotation), 0]}
        onPointerDown={e => {
          e.stopPropagation()

          if (focusOn) {
            focusOn(e.eventObject.parent, uri)
          }
        }}
        dispose={null}
        ref={ref}
        {...props}
      />
    </>
  ) : null
}

Artefact3D.propTypes = {
  object: PropTypes.shape({
    url: PropTypes.string,
  }),
  title: PropTypes.string,
  scaleFactor: PropTypes.number,
  verticalOffset: PropTypes.number,
  rotation: PropTypes.number,
  wireframe: PropTypes.bool,
  focusOn: PropTypes.func,
}

Artefact3D.defaultProps = {
  title: '',
  scaleFactor: 1,
  verticalOffset: 0,
  rotation: 0,
  wireframe: false,
}

export default Artefact3D
