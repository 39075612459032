import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import BrandArrow from '../svgs/brandmarks/arrow.svg'
import BrandBrackets from '../svgs/brandmarks/brackets.svg'
import BrandCircle from '../svgs/brandmarks/circle.svg'
import BrandCurves from '../svgs/brandmarks/curves.svg'
import BrandMarks from '../svgs/brandmarks/marks.svg'
import BrandStar from '../svgs/brandmarks/star.svg'

const BrandmarkCycle = ({ isPlaying, duration }) => {
  const [brandmarkShownIndex, setBrandmarkShownIndex] = useState(0)
  const numberOfBrandmarks = 6

  // cycle through brandmarks
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setBrandmarkShownIndex((brandmarkShownIndex + 1) % numberOfBrandmarks)
      }
    }, duration)

    return () => {
      clearInterval(interval)
    }
  }, [brandmarkShownIndex, duration, isPlaying])

  return (
    <>
      {brandmarkShownIndex === 0 ? <BrandArrow /> : null}
      {brandmarkShownIndex === 1 ? <BrandBrackets /> : null}
      {brandmarkShownIndex === 2 ? <BrandCircle /> : null}
      {brandmarkShownIndex === 3 ? <BrandCurves /> : null}
      {brandmarkShownIndex === 4 ? <BrandMarks /> : null}
      {brandmarkShownIndex === 5 ? <BrandStar /> : null}
    </>
  )
}

BrandmarkCycle.propTypes = {
  isPlaying: PropTypes.bool,
  duration: PropTypes.number,
}

BrandmarkCycle.defaultProps = {
  isPlaying: true,
  duration: 200,
}

export default BrandmarkCycle
