module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"this-place-of-mine","short_name":"tpom","start_url":"/","background_color":"#2027EB","theme_color":"#2027EB","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b11433794b41f710f6b9e07481130def"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-6D103LK71T","cookieName":"tpomMarketing","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"tpomMarketing","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"tpom-facebook-pixel"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
