import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  TextureLoader,
  FrontSide,
  BackSide,
  DoubleSide,
  NearestFilter,
  RGBFormat,
} from 'three'
import { useFrame, useLoader } from 'react-three-fiber'
import { Plane } from '@react-three/drei'
import gsap from 'gsap'

import { useStore } from '../../../../../store/index'

import {
  calculateTitleDimensions,
  createTitleTexture,
} from '../../../../../utilities/textures'

const ArtefactImage = ({ id, image, title, uri, focusOn, ...props }) => {
  const ref = useRef()
  const titleCylinderFrontRef = useRef()
  const titleTextureMaterialFrontRef = useRef()
  const titleCylinderBackRef = useRef()
  const titleTextureMaterialBackRef = useRef()
  const titleTextureRef = useRef()
  const tweenValuesRef = useRef({
    titleOpacity: 0,
  })

  const focussedObjectUrl = useStore(state => state.focussedObjectUrl)

  // check for pan change
  useStore.subscribe(
    artefactToPanToID => {
      if (artefactToPanToID && artefactToPanToID.id === id && ref.current) {
        focusOn(ref.current.parent, uri)
      }
    },
    state => state.artefactToPanToID
  )

  const aspectRatio =
    image.optimisedImages.placeholderWidth /
    image.optimisedImages.placeholderHeight

  const width = 2 * aspectRatio
  const height = 2

  const {
    titleCylinderRadius,
    titleCylinderHeight,
    titleCylinderTextureWidth,
    titleCylinderTextureHeight,
  } = calculateTitleDimensions(width, height)

  useEffect(() => {
    titleTextureRef.current = createTitleTexture(
      title,
      titleCylinderTextureWidth,
      titleCylinderTextureHeight
    )
  }, [title, titleCylinderTextureHeight, titleCylinderTextureWidth])

  useEffect(() => {
    if (focussedObjectUrl === uri) {
      gsap.to(tweenValuesRef.current, {
        titleOpacity: 1,
        duration: 1,
      })
    } else {
      gsap.to(tweenValuesRef.current, {
        titleOpacity: 0,
        duration: 1,
      })
    }
  }, [focussedObjectUrl, uri])

  const rotationSpeed = 0.5

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.y += rotationSpeed * delta
    }

    if (titleCylinderFrontRef.current && titleCylinderBackRef.current) {
      titleCylinderFrontRef.current.rotation.y -= rotationSpeed * delta
      titleCylinderBackRef.current.rotation.y -= rotationSpeed * delta
    }

    if (
      titleTextureMaterialFrontRef.current &&
      titleTextureMaterialBackRef.current
    ) {
      titleTextureMaterialFrontRef.current.opacity =
        tweenValuesRef.current.titleOpacity
      titleTextureMaterialBackRef.current.opacity =
        tweenValuesRef.current.titleOpacity
    }
  })

  const texture = useLoader(TextureLoader, image.optimisedImages.src)
  texture.flipY = false
  texture.minFilter = NearestFilter
  texture.magFilter = NearestFilter
  texture.format = RGBFormat

  return (
    <>
      {titleTextureRef.current ? (
        <>
          <mesh
            position-y={titleCylinderHeight / 3}
            ref={titleCylinderFrontRef}
            receiveShadow={false}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={FrontSide}
              map={titleTextureRef.current}
              transparent={true}
              ref={titleTextureMaterialFrontRef}
              opacity={0}
              depthTest={false}
            />
          </mesh>
          <mesh
            position-y={titleCylinderHeight / 3}
            ref={titleCylinderBackRef}
            receiveShadow={false}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={BackSide}
              map={titleTextureRef.current}
              transparent={true}
              ref={titleTextureMaterialBackRef}
              opacity={0}
            />
          </mesh>
        </>
      ) : null}

      <Plane
        castShadow={true}
        ref={ref}
        args={[width, height]}
        rotation={[0, 0, Math.PI]}
        position-y={height / 2}
        onPointerDown={e => {
          e.stopPropagation()

          if (focusOn) {
            focusOn(e.eventObject.parent, uri)
          }
        }}
        {...props}
      >
        <meshPhongMaterial
          roughness={1}
          metalness={0.5}
          shininess={100}
          attach="material"
          side={DoubleSide}
          map={texture}
        />
      </Plane>
    </>
  )
}

ArtefactImage.propTypes = {
  image: PropTypes.shape({
    optimisedImages: PropTypes.object,
    url: PropTypes.string,
    focusOn: PropTypes.func,
  }),
}

export default ArtefactImage
