import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const ProgressRing = ({ radius, stroke, progress }) => {
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffsetRef = useRef(
    circumference - (progress / 100) * circumference
  )

  useEffect(() => {
    strokeDashoffsetRef.current =
      circumference - (progress / 100) * circumference
  }, [circumference, progress])

  return (
    <svg
      className="progress-ring"
      height={radius * 2}
      width={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
    >
      <circle
        stroke="white"
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset: strokeDashoffsetRef.current }}
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
}

ProgressRing.propTypes = {
  radius: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
}

export default ProgressRing
