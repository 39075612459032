import { CanvasTexture } from 'three'

export const calculateTitleDimensions = (objectWidth, objectHeight) => {
  //const titleCylinderSize = 1.4

  //const titleCylinderRadius = (objectWidth * titleCylinderSize) / 2
  const titleCylinderRadius = 2.5
  //const titleCylinderHeight = objectHeight * titleCylinderSize
  const titleCylinderHeight = 2.8

  const titleCylinderTextureWidthRatio = 2 * Math.PI * titleCylinderRadius
  const titleCylinderTextureHeightRatio = titleCylinderHeight

  const titleCylinderTextureHeight = 512

  const titleCylinderTextureWidth =
    titleCylinderTextureHeight *
    (titleCylinderTextureWidthRatio / titleCylinderTextureHeightRatio)

  return {
    titleCylinderHeight,
    titleCylinderRadius,
    titleCylinderTextureWidth,
    titleCylinderTextureHeight,
  }
}

export const createTitleTexture = (title, textureWidth, textureHeight) => {
  const idealCharCount = 50
  const lcTitle = title.toLowerCase()
  const titleCharCount = lcTitle.length
  const numberOfRepetitions = Math.ceil(idealCharCount / titleCharCount)

  const canvas = document.createElement('canvas')
  canvas.style.display = 'none'
  const ctx = canvas.getContext('2d')
  document.body.appendChild(ctx.canvas)
  ctx.canvas.width = textureWidth
  ctx.canvas.height = textureHeight
  ctx.font = 'normal normal 700 100px Syne'
  ctx.fillStyle = 'white'
  ctx.textAlign = 'center'
  ctx.fillText(
    `${lcTitle}  `.repeat(numberOfRepetitions),
    ctx.canvas.width / 2,
    ctx.canvas.height / 2,
    textureWidth
  )
  return new CanvasTexture(ctx.canvas)
}
