import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useCookies } from 'react-cookie'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { AudioContext } from 'standardized-audio-context'

import { css } from '@emotion/core'
import tw from 'twin.macro'
import { useStore } from '../store/index'

import {
  FormLayout,
  FormColumn,
  Checkbox,
  SubmitButton,
} from './forms/components'

const CookieForm = ({ onAccept, openTerms }) => {
  const [cookies, setCookies] = useCookies()
  const location = useLocation()

  const setIsMuted = useStore(state => state.setIsMuted)
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  const initialValues = {}
  initialValues.consentNecessary = true
  initialValues.consentFunctional = cookies.tpomFunctional
    ? cookies.tpomFunctional === 'true'
    : true
  initialValues.consentMarketing = cookies.tpomMarketing
    ? cookies.tpomMarketing === 'true'
    : true

  return (
    <div>
      <div
        tw="text-xs md:text-sm lg:text-base my-8 text-brand-dark-grey text-center"
        css={css`
          @media (max-height: 900px) {
            ${tw`text-xs!`}
          }
        `}
      >
        [this place of mine] uses cookies to enhance your experience. By
        entering you agree to the{' '}
        <button tw="cursor-pointer underline" onClick={openTerms}>
          cookie policy here
        </button>
        .
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          if (values.consentMarketing && !cookies.tpomMarketing) {
            initializeAndTrack(location)
          }

          setCookies('tpomConsent', values.consentNecessary, { path: '/' })
          setCookies('tpomFunctional', values.consentFunctional, { path: '/' })
          setCookies('tpomMarketing', values.consentMarketing, { path: '/' })
          onAccept()
        }}
      >
        {({ status }) => (
          <Form name="cookieConsent" data-netlify={true}>
            {(!status || !status.sent) && (
              <FormLayout>
                <FormColumn width="1/3">
                  <Checkbox name="consentNecessary" disabled="true">
                    Necessary
                  </Checkbox>
                </FormColumn>
                <FormColumn width="1/3">
                  <Checkbox name="consentFunctional">Functional</Checkbox>
                </FormColumn>
                <FormColumn width="1/3">
                  <Checkbox name="consentMarketing">Marketing</Checkbox>
                </FormColumn>
                <div tw="w-full flex justify-center mt-4 lg:mt-8">
                  <SubmitButton
                    text="accept and enter"
                    onClick={() => {
                      const triggerSound = new Audio('/audio/silence.mp3')
                      const audioCtx = new AudioContext()
                      if (audioCtx.state === 'suspended') {
                        audioCtx.resume()
                      }
                      setIsMuted(false)
                      setAudioSampleTriggers('uiClick', true)
                      setTimeout(() => {
                        triggerSound.play()
                        setIsMuted(false)
                      }, 1000)
                    }}
                  />
                </div>
              </FormLayout>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

CookieForm.propTypes = {
  onAccept: PropTypes.func,
  openTerms: PropTypes.func,
}

CookieForm.defaultProps = {
  onAccept: () => {},
  openTerms: () => {},
}

export default CookieForm
