import React, { useEffect, useRef } from 'react'
import Pizzicato from 'pizzicato'

import 'twin.macro'

import { useStore } from '../../store/index'

import { checkIfiOS } from '../../utilities/browser'

const AudioSamples = () => {
  const sampleNamesRef = useRef([
    'uiClick',
    'uiOpen',
    'uiClose',
    'uiQuestionComplete',
    'uiNotification',
  ])
  let audioSamplesRef = useRef({})

  const isMuted = useStore(state => state.isMuted)
  const audioSampleTriggers = useStore(state => state.audioSampleTriggers)
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  useEffect(() => {
    if (!checkIfiOS()) {
      audioSamplesRef.current = sampleNamesRef.current.reduce(
        (acc, sampleName) => {
          acc[sampleName] = {
            sound: new Pizzicato.Sound(
              {
                source: 'file',
                options: { path: `/audio/${sampleName}.mp3` },
              },
              () => {
                audioSamplesRef.current[sampleName].initialized = true
              }
            ),
            initialized: false,
          }
          return acc
        },
        {}
      )

      sampleNamesRef.current.forEach(sampleName => {
        setAudioSampleTriggers(sampleName, false)
      })
    }
  }, [setAudioSampleTriggers])

  // trigger samples
  useEffect(() => {
    if (!checkIfiOS()) {
      for (const sampleName in audioSampleTriggers) {
        if (
          audioSampleTriggers.hasOwnProperty(sampleName) &&
          audioSampleTriggers[sampleName]
        ) {
          if (
            audioSamplesRef.current[sampleName].sound &&
            audioSamplesRef.current[sampleName].initialized &&
            !isMuted
          ) {
            audioSamplesRef.current[sampleName].sound.stop()
            audioSamplesRef.current[sampleName].sound.play()
          }
          setAudioSampleTriggers(sampleName, false)
        }
      }
    }
  }, [audioSampleTriggers, isMuted, setAudioSampleTriggers])

  return <></>
}

export default AudioSamples
