import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useStore } from '../../store/index'

const QuadrantLayout = ({ width, height, children }) => {
  const [quadrantOffsets, setQuadrantOffsets] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  /* this stores the previous quadrant offsets to check against if we need to rerender */
  const prevQuadrantOffsetsRef = useRef({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  /* get the coords the camera is looking at, store them in a ref,
     and subscribe to changes */
  const lookingAtRef = useRef(useStore.getState().lookingAt)

  useEffect(
    () =>
      useStore.subscribe(
        lookingAt => (lookingAtRef.current = lookingAt),
        state => state.lookingAt
      ),
    []
  )

  const lookingAtModifier = {
    x: 2,
    y: 2,
  }

  /* every half second, use the looking at value to calculate
     the quadrant offsets */
  useEffect(() => {
    const calculateQuadrantOffsets = () => {
      // lets add a modifier so we bring in the repeated elements earlier, to hide popin
      const top =
        Math.floor(
          (-lookingAtRef.current.y + lookingAtModifier.y + (0.5 * height) / 2) /
            height
        ) * 2

      const bottom =
        Math.floor(
          (-lookingAtRef.current.y + lookingAtModifier.y + (1.5 * height) / 2) /
            height
        ) * 2

      const left =
        Math.floor(
          (lookingAtRef.current.x + lookingAtModifier.x + (1.5 * width) / 2) /
            width
        ) * 2

      const right =
        Math.floor(
          (lookingAtRef.current.x + lookingAtModifier.x + (0.5 * width) / 2) /
            width
        ) * 2

      if (
        top !== prevQuadrantOffsetsRef.current.top ||
        bottom !== prevQuadrantOffsetsRef.current.bottom ||
        left !== prevQuadrantOffsetsRef.current.left ||
        right !== prevQuadrantOffsetsRef.current.right
      ) {
        const newQuadrantOffsets = {
          top,
          bottom,
          left,
          right,
        }
        setQuadrantOffsets(newQuadrantOffsets)
        prevQuadrantOffsetsRef.current = newQuadrantOffsets
      }
    }

    calculateQuadrantOffsets()
    const interval = setInterval(() => calculateQuadrantOffsets(), 200)

    return () => {
      clearInterval(interval)
    }
  }, [height, lookingAtModifier.x, lookingAtModifier.y, width])

  return children.length === 4 ? (
    <group position={[0, 0, 0]}>
      {React.cloneElement(children[0], {
        offsetNumberX: quadrantOffsets.left,
        offsetNumberY: quadrantOffsets.top,
        width: width / 2,
        height: height / 2,
        originX: -width / 2,
        originY: height / 2,
      })}
      {React.cloneElement(children[1], {
        offsetNumberX: quadrantOffsets.right,
        offsetNumberY: quadrantOffsets.top,
        width: width / 2,
        height: height / 2,
        originX: 0,
        originY: height / 2,
      })}
      {React.cloneElement(children[2], {
        offsetNumberX: quadrantOffsets.left,
        offsetNumberY: quadrantOffsets.bottom,
        width: width / 2,
        height: height / 2,
        originX: -width / 2,
        originY: 0,
      })}
      {React.cloneElement(children[3], {
        offsetNumberX: quadrantOffsets.right,
        offsetNumberY: quadrantOffsets.bottom,
        width: width / 2,
        height: height / 2,
        originX: 0,
        originY: 0,
      })}
    </group>
  ) : null
}

QuadrantLayout.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
}

export default QuadrantLayout
