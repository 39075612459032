import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'
import { useLoader } from 'react-three-fiber'

function ktx2() {
  return loader => {
    const ktx2Loader = new KTX2Loader()
    loader.setKTX2Loader(ktx2Loader)
  }
}

export function useGLTF(path, useKTX2 = true) {
  const gltf = useLoader(GLTFLoader, path, useKTX2 ? ktx2() : undefined)
  return gltf
}

useGLTF.preload = (path, useKTX2 = true) =>
  useLoader.preload(GLTFLoader, path, useKTX2 ? ktx2() : undefined)
