import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { HamburgerThreeDX } from 'react-animated-burgers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import Button from './button'
import CircleIconButton from './circle-icon-button'

import LogoImg from '../svgs/logo.svg'
import AudioOnImg from '../svgs/audio-on-icon.svg'
import AudioOffImg from '../svgs/audio-off-icon.svg'

import { useStore } from '../store/index'

import { checkIfiOS } from '../utilities/browser'

const StyledHeader = styled(motion.header)`
  ${tw`py-4 fixed top-0 inset-x-0 w-full md:py-8 pointer-events-none outline-none!`}
  ${({ reverseTextColour }) =>
    reverseTextColour ? tw`text-white` : tw`text-black`}

  ${({ isOnSinglePage }) => (isOnSinglePage ? tw`z-40` : 'z-index:12;')}
`

const StyledLogoWrapper = styled.div`
  svg path,
  svg polygon {
    transition: fill 0.1s linear;
  }

  ${({ reverseTextColour }) =>
    reverseTextColour && 'svg path, svg polygon { fill: black; }'}
`

const StyledHamburger = styled(HamburgerThreeDX)`
  ${tw`rounded-full! outline-none!`}
  width:38px !important;
  height: 38px !important;
  transition-property: all !important;
  transition-duration: 0.2s !important;

  > div {
    position: relative;
  }

  &:hover {
    ${({ buttonColor }) => {
      switch (buttonColor) {
        case 'black':
          return tw`hover:bg-gray-700! hover:text-white!`
        default:
          return tw`hover:bg-brand-light-grey! hover:text-black!`
      }
    }}
  }

  &:focus {
    ${({ buttonColor }) => {
      switch (buttonColor) {
        case 'black':
          return tw`bg-gray-700! text-white!`
        default:
          return tw`bg-brand-light-grey! text-black!`
      }
    }}
  }
`

const SiteHeadItem = styled(motion.div)`
  ${tw`flex flex-row items-center pointer-events-auto`}
  width: 190px;
`

const variants = {
  hide: { y: '-100%' },
  show: { y: 0 },
}

const bgVariants = {
  bgHide: { y: '-100%' },
  bgShow: { y: 0 },
}

const closeButtonVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
}

const SiteHead = ({
  show,
  showBg,
  reverseTextColour,
  showOverlay,
  setShowOverlay,
  isOnSinglePage,
}) => {
  const [isiOS, setIsiOS] = useState(false)

  const isMuted = useStore(state => state.isMuted)
  const setIsMuted = useStore(state => state.setIsMuted)

  const toggleAudio = () => {
    setIsMuted(!isMuted)
  }

  useEffect(() => {
    setIsiOS(checkIfiOS())
  }, [isiOS])

  return (
    <AnimatePresence>
      {show && (
        <StyledHeader
          key="site-head"
          variants={variants}
          initial={false}
          animate="show"
          exit="hide"
          transition={{ type: 'tween' }}
          reverseTextColour={reverseTextColour}
          isOnSinglePage={isOnSinglePage}
        >
          <div tw="relative px-4 md:px-12">
            <AnimatePresence initial={false}>
              {showBg && (
                <motion.div
                  key="site-head-bg"
                  tw="absolute inset-0 z-20 bg-white"
                  variants={bgVariants}
                  initial="bgHide"
                  animate="bgShow"
                  exit="bgHide"
                  transition={{ type: 'tween' }}
                ></motion.div>
              )}
            </AnimatePresence>
            <div tw="flex justify-between relative z-40">
              <SiteHeadItem
                tw="justify-start"
                css={css`
                  svg {
                    height: auto;
                    width: 90px;

                    @media (min-width: 768px) {
                      width: 130px;
                    }
                  }
                `}
              >
                <StyledLogoWrapper reverseTextColour={reverseTextColour}>
                  <Link to="/" aria-label="Return to This Place of Mine home">
                    <LogoImg />
                  </Link>
                </StyledLogoWrapper>
              </SiteHeadItem>
              <AnimatePresence>
                {isOnSinglePage ? (
                  <SiteHeadItem
                    tw="justify-center"
                    variants={closeButtonVariants}
                    initial="hide"
                    animate="show"
                    exit="hide"
                    transition={{
                      duration: 0.4,
                      type: 'tween',
                      ease: 'easeOut',
                    }}
                  >
                    <CircleIconButton
                      icon={<FontAwesomeIcon icon={['fal', 'times']} />}
                      tw="mr-2"
                      size="small"
                      playSample={true}
                      aria-label="Return to This Place of Mine home"
                      onClick={() => {
                        navigate('/')
                      }}
                    />
                  </SiteHeadItem>
                ) : null}
              </AnimatePresence>
              <SiteHeadItem tw="justify-end">
                <Button
                  text="about"
                  buttonStyle={reverseTextColour ? 'black' : 'white'}
                  tw="mr-2 hidden md:block"
                  to="/about"
                  playSample={true}
                />
                {!isiOS ? (
                  <CircleIconButton
                    icon={
                      isMuted ? (
                        <AudioOffImg></AudioOffImg>
                      ) : (
                        <AudioOnImg></AudioOnImg>
                      )
                    }
                    tw="mr-2"
                    size="small"
                    reverseColour={reverseTextColour}
                    playSample={true}
                    onClick={toggleAudio}
                    aria-label="Toggle audio on and off"
                  />
                ) : null}
                <StyledHamburger
                  buttonWidth={20}
                  buttonColor={reverseTextColour ? 'black' : 'white'}
                  isActive={false}
                  onClick={() => {
                    setShowOverlay(!showOverlay)
                  }}
                  barColor={reverseTextColour ? 'white' : 'black'}
                />
              </SiteHeadItem>
            </div>
          </div>
        </StyledHeader>
      )}
    </AnimatePresence>
  )
}

SiteHead.propTypes = {
  show: PropTypes.bool,
  showBg: PropTypes.bool,
  reverseTextColour: PropTypes.bool,
  showOverlay: PropTypes.bool,
  setShowOverlay: PropTypes.func,
  setOverlayContents: PropTypes.func,
  isOnSinglePage: PropTypes.bool,
}

SiteHead.defaultProps = {
  show: true,
  showBg: true,
  reverseTextColour: false,
  showOverlay: false,
  setShowOverlay: null,
  setOverlayContents: null,
  isOnSinglePage: false,
}

export default SiteHead
