import React from 'react'
import PropTypes from 'prop-types'

import GridItem from './item/index'

const GridQuadrant = ({
  label,
  rows,
  spacing,
  width,
  height,
  originX,
  originY,
  offsetNumberX,
  offsetNumberY,
  totalGridRowCount,
  questionNumberMappings,
  focusOn,
}) => {
  const incrementX = spacing
  const incrementY = -spacing

  const isTopQuadrant = ['topLeft', 'topRight'].includes(label)
  const isLeftQuadrant = ['topLeft', 'bottomLeft'].includes(label)
  const isGridEvenVertically = totalGridRowCount % 2 === 0

  return (
    <group
      position={[
        originX + offsetNumberX * width,
        originY + offsetNumberY * height,
        0,
      ]}
    >
      {rows.map((row, y) => {
        const isQuadrantRowEvenHorizontally = row.totalRowItemCount % 2 === 0

        return row.items.map((item, x) => {
          /* - if there's an even number of items in the quadrant's row, shift items
               right by half a horizontal spacing unit to align it as per a flexbox
               style justify center.
             - if there's an odd number of columns across the whole grid, shift the left
               quadrant items to the right as there's less items there. (HMMM WAS THIS WRONG?)
            -  if it's the left quadrant and there's less items in the row then items
               need to be flush with right side of quadrant so move items further to the
               right */

          const posX =
            x * incrementX +
            (isQuadrantRowEvenHorizontally ? incrementX / 2 : 0) +
            //(isLeftQuadrant && !isGridEvenHorizontally ? incrementX / 2 : 0) +
            (isLeftQuadrant
              ? (width / spacing - row.items.length) * incrementX
              : 0)

          /* - if there's an even number of rows in the grid, shift rows down by half
               a vertical spacing unit to align it as per a flexbox style justify center
             - and if it's odd, then just do this for the top quadrant, as there's less
               items there */
          const posY =
            y * incrementY +
            (isGridEvenVertically ? incrementY / 2 : 0) +
            (isTopQuadrant && !isGridEvenVertically ? incrementY / 2 : 0)

          return (
            <GridItem
              key={`${x}${y}`}
              item={item}
              focusOn={focusOn}
              posX={posX}
              posY={posY}
              questionNumberMappings={questionNumberMappings}
            />
          )
        })
      })}
    </group>
  )
}

GridQuadrant.propTypes = {
  label: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  spacing: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  originX: PropTypes.number,
  originY: PropTypes.number,
  offsetNumberX: PropTypes.number,
  offsetNumberY: PropTypes.number,
  totalGridRowCount: PropTypes.number.isRequired,
  focusOn: PropTypes.func,
}

GridQuadrant.defaultProps = {
  width: 200,
  height: 200,
  originX: 0,
  originY: 0,
  offsetNumberX: 0,
  offsetNumberY: 0,
  focusOn: null,
}

export default GridQuadrant
