import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { css } from '@emotion/core'
import 'twin.macro'

import Marquee from './marquee'

const PanelHeader = ({ heading, onClose }) => {
  const headingLowercase = heading.toLowerCase()

  return (
    <header tw="bg-brand-blue text-xl pr-4 py-3 absolute z-50 top-0 w-full flex justify-between items-center">
      <div tw="pl-3 pr-3">
        <FontAwesomeIcon icon={['far', 'arrow-down']} />
      </div>
      {headingLowercase && (
        <div tw="flex-initial overflow-hidden pr-4">
          <Marquee size="small">
            {headingLowercase}&nbsp;&nbsp;&nbsp;&nbsp;{headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;{headingLowercase}&nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}&nbsp;&nbsp;&nbsp;&nbsp;{headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;{headingLowercase}&nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {headingLowercase}
          </Marquee>
        </div>
      )}
      <div tw="flex justify-end items-center flex-1">
        <button
          tw="pl-1 focus:outline-none focus:text-brand-light-grey"
          aria-label={`Close ${heading}`}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </div>
    </header>
  )
}

PanelHeader.propTypes = {}

PanelHeader.defaultProps = {}

export default PanelHeader
