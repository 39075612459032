import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import '../styles/modal.css'

import 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Modal = ({
  isOpen,
  handleCloseModal,
  contentLabel,
  shouldCloseOnOverlayClick,
  children,
}) => {
  ReactModal.setAppElement('#___gatsby')

  const customStyles = {
    overlay: {
      position: 'fixed',
      zIndex: 50,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,.8)',
    },
    content: {
      top: '1rem',
      left: 'auto',
      right: 'auto',
      bottom: '1rem',
      backgroundColor: '#fff',
      padding: '2rem',
      borderRadius: '.125rem',
      overflowY: 'auto',
      maxHeight: '100vh',
      width: '100%',
      position: 'relative',
      minWidth: '300px',
      maxWidth: '800px',
    },
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel={contentLabel}
      onRequestClose={handleCloseModal}
      closeTimeoutMS={250}
    >
      <button
        tw="text-white absolute top-0 right-0 mt-2 mr-4"
        onClick={handleCloseModal}
      >
        <FontAwesomeIcon icon={['fal', 'times']} />
      </button>

      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  contentLabel: PropTypes.string,
  handleCloseModal: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  children: PropTypes.node,
}

Modal.defaultProps = {
  isOpen: false,
  contentLabel: '',
  handleCloseModal: null,
  shouldCloseOnOverlayClick: true,
  children: null,
}

export default Modal
