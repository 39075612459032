import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import { useStore } from '../store/index'

const StyledButton = styled.button`
  ${tw`rounded-full transform focus:outline-none cursor-pointer pointer-events-auto transition duration-200`}

  ${({ size }) =>
    size === 'small' ? 'width: 38px; height: 38px;' : tw`w-14 h-14`}

  ${({ reverseColour }) =>
    reverseColour
      ? tw`bg-black text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700`
      : tw`bg-white text-black hover:bg-brand-light-grey focus:outline-none focus:bg-brand-light-grey`}

  ${({ reverseColour }) =>
    reverseColour
      ? '#Path_69, #Path_70, #Path_72, #Line_16, #Line_17 { stroke: white; }; #Path_71, #Path_73 { fill: white; }'
      : ''}
`

const StyledNotificationIcon = styled.div`
  ${tw`absolute w-4 h-4 bg-brand-red rounded-full text-white flex justify-center items-center text-xs`}

  ${({ size }) =>
    size === 'small' ? 'top: -5px; right: -5px;' : `top: -1px; right: -1px;`}
`

const CircleIconButton = ({
  icon,
  notificationCount,
  size,
  reverseColour,
  playSample,
  onClick,
  ...props
}) => {
  const [prevNotificationCount, setPrevNotificationCount] = useState(
    notificationCount
  )

  const notificationCircleRef = useRef()

  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  const playNotificationAnimation = () => {
    const tl = gsap.timeline()
    tl.set(notificationCircleRef.current, { scale: 1, autoAlpha: 1 })
    tl.to(notificationCircleRef.current, {
      scale: 2,
      autoAlpha: 0,
      duration: 1,
      ease: 'power2.out',
    })
    tl.play()
  }

  useEffect(() => {
    if (notificationCount > prevNotificationCount) {
      playNotificationAnimation()
      setAudioSampleTriggers('uiNotification', true)
    }

    setPrevNotificationCount(notificationCount)
  }, [notificationCount, prevNotificationCount, setAudioSampleTriggers])

  return (
    <StyledButton
      onClick={() => {
        onClick()
        if (playSample) {
          setAudioSampleTriggers('uiClick', true)
        }
      }}
      size={size}
      reverseColour={reverseColour}
      {...props}
    >
      <div
        css={css`
          ${tw`absolute inset-0 flex justify-center items-center`}

          svg {
            ${tw`h-auto`}
            width: 50%;
          }
        `}
      >
        {icon}
      </div>
      <div
        tw="bg-transparent border-brand-red border-2 rounded-full absolute inset-0 opacity-0"
        ref={notificationCircleRef}
      ></div>
      {notificationCount > 0 && (
        <StyledNotificationIcon size={size}>
          <span
            tw="relative"
            css={css`
              top: -1px;
            `}
          >
            {notificationCount}
          </span>
        </StyledNotificationIcon>
      )}
    </StyledButton>
  )
}

CircleIconButton.propTypes = {
  size: PropTypes.string,
  reverseColour: PropTypes.bool,
  playSample: PropTypes.bool,
}

CircleIconButton.defaultProps = {
  size: 'large',
  reverseColour: false,
  playSample: false,
}

export default CircleIconButton
