// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-citizen-futurists-artwork-js": () => import("./../../../src/templates/citizen-futurists/artwork.js" /* webpackChunkName: "component---src-templates-citizen-futurists-artwork-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-partners-js": () => import("./../../../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-young-producers-artefact-js": () => import("./../../../src/templates/young-producers/artefact.js" /* webpackChunkName: "component---src-templates-young-producers-artefact-js" */)
}

