import React from 'react'
import PropTypes from 'prop-types'

import 'twin.macro'

import ArticleHeading from './article-heading'

const Text = ({ heading, body }) => (
  <div>
    {heading && <ArticleHeading>{heading}</ArticleHeading>}
    {body && (
      <div
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      ></div>
    )}
  </div>
)

Text.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
}

Text.defaultProps = {
  heading: '',
  body: '',
}

export default Text
