import React, { useEffect, useRef, useCallback, Suspense } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Vector3 } from 'three'
import { useFrame, useThree, Canvas } from 'react-three-fiber'
import { PerspectiveCamera } from '@react-three/drei'
import gsap from 'gsap'

import { css } from '@emotion/core'
import 'twin.macro'

import { useStore } from '../../store/index'

import Environment from './environment/index'
import Floor from './floor/index'
import Grid from './grid/index'
import Button from '../button'

// memoize the scene so as not to re-render if the parent's state changes
const Scene = React.memo(({ grid, questionNumberMappings }) => {
  const cameraDistHorizontal = 12
  const cameraDistVertical = 11
  const cameraFovStandard = 35
  const cameraFovZoomed = 25

  const {
    camera,
    gl: { compile, domElement },
    scene,
  } = useThree()

  // this local state should not trigger a re-render
  const isFocussedRef = useRef(false)

  // same for this shared state
  const setLookingAt = useStore(state => state.setLookingAt)
  const setFocussedObjectUrl = useStore(state => state.setFocussedObjectUrl)
  const setTriggerWorldZoomOut = useStore(state => state.setTriggerWorldZoomOut)
  const setActiveQuestion = useStore(state => state.setActiveQuestion)
  const setArtefactToPanToID = useStore(state => state.setArtefactToPanToID)

  // refs for scene elements
  const prevCameraPositionRef = useRef()
  const prevControlsTargetRef = useRef()
  const controlsRef = useRef()
  const lightRef = useRef()
  const lightTargetRef = useRef()
  const cameraTrackerRef = useRef()

  // attempting to load textures to GPU
  useEffect(() => {
    compile(scene, camera)
  }, [camera, compile, scene])

  // update looking at position for grid repeats
  useFrame(() => {
    const a = Math.PI / 4

    const camX = controlsRef.current.target.x
    const camY = controlsRef.current.target.z

    /* translating coord system */
    const x = camX * Math.sin(a) - camY * Math.cos(a)
    const y = camX * Math.cos(a) + camY * Math.sin(a)

    setLookingAt(x, y)

    /* the camera tracker object which positions the lights needs to
       follow the camera, apart from when the scene is focussed, when the
       camera is doing its own thing, so this is tweened separarely to
       its new position in the function */
    if (!isFocussedRef.current) {
      cameraTrackerRef.current.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      )
    }

    // set the light position to just behind camera tracker pos
    lightRef.current.position.set(
      cameraTrackerRef.current.position.x - 5,
      cameraTrackerRef.current.position.y - 1,
      cameraTrackerRef.current.position.z - 1
    )

    // and looking straight at the ground in front of the camera
    lightTargetRef.current.position.set(camX, 0.5, camY)
  })

  // update map controls
  useFrame(() => controlsRef.current.update())

  const escFunction = useCallback(
    e => {
      if (e.keyCode === 27) {
        setTriggerWorldZoomOut(true)
      }
    },
    [setTriggerWorldZoomOut]
  )

  const focusOn = (object, uri, question) => {
    setArtefactToPanToID(null)
    if (!isFocussedRef.current) {
      const duration = 1.5

      isFocussedRef.current = true

      const distInFront = 7

      const lookAtPos = object.getWorldPosition()

      const newCameraPos = new Vector3(
        lookAtPos.x,
        lookAtPos.y + 4.4,
        lookAtPos.z + distInFront
      )

      const distanceBetweenCameraAndTarget =
        camera.position.z - controlsRef.current.target.z

      // remember camera data for return focus animation
      prevCameraPositionRef.current = new Vector3(
        newCameraPos.x,
        camera.position.y,
        newCameraPos.z + cameraDistHorizontal - 6.428
      )

      prevControlsTargetRef.current = new Vector3(
        newCameraPos.x,
        controlsRef.current.target.y,
        prevCameraPositionRef.current.z - distanceBetweenCameraAndTarget
      )

      // animate camera to new position
      controlsRef.current.enabled = false

      const tl = gsap.timeline({
        delay: 0.25,
      })

      tl.add('start')

      tl.to(camera.position, {
        duration,
        ease: 'power2',
        x: newCameraPos.x,
        y: newCameraPos.y,
        z: newCameraPos.z,
      })

      tl.to(
        camera,
        {
          duration,
          fov: cameraFovZoomed,
          onUpdate() {
            camera.updateProjectionMatrix()
          },
        },
        'start'
      )

      tl.to(
        controlsRef.current.target,
        {
          duration,
          ease: 'power2',
          x: lookAtPos.x,
          y: lookAtPos.y,
          z: lookAtPos.z,
        },
        'start'
      )

      /* the camera tracker object which positions the lights needs to move
       to where the camera will be positioned in focussed out view,
       to keep the lights stable - don't want them following the lowered cam */
      tl.to(
        cameraTrackerRef.current.position,
        {
          duration,
          x: prevCameraPositionRef.current.x,
          y: prevCameraPositionRef.current.y,
          z: prevCameraPositionRef.current.z,
        },
        'start'
      )

      tl.add(() => {
        document.addEventListener('keydown', escFunction, false)
        if (question) {
          setActiveQuestion(question)
        } else if (uri) {
          setFocussedObjectUrl(uri)
        }
      })
    }
  }

  useEffect(() => {
    const removeFocus = () => {
      if (isFocussedRef.current) {
        const duration = 0.8

        document.removeEventListener('keydown', escFunction, false)

        setFocussedObjectUrl(null)
        setTriggerWorldZoomOut(false)

        const tl = gsap.timeline({
          delay: 0.1,
        })

        tl.add('start')

        tl.to(camera.position, {
          ease: 'power2',
          duration: duration - 0.1,
          x: prevCameraPositionRef.current.x,
          y: prevCameraPositionRef.current.y,
          z: prevCameraPositionRef.current.z,
        })

        tl.to(
          camera,
          {
            duration,
            ease: 'power3',
            fov: cameraFovStandard,
            onUpdate() {
              camera.updateProjectionMatrix()
            },
          },
          'start'
        )

        tl.to(
          controlsRef.current.target,
          {
            ease: 'power2',
            duration: duration - 0.1,
            x: prevControlsTargetRef.current.x,
            y: prevControlsTargetRef.current.y,
            z: prevControlsTargetRef.current.z,
          },
          'start'
        )

        tl.add(() => {
          isFocussedRef.current = false
          controlsRef.current.enabled = true
          prevCameraPositionRef.current = null
          prevControlsTargetRef.current = null
        })
      }
    }

    // check for back button clicked
    useStore.subscribe(
      triggerWorldZoomOut => {
        if (triggerWorldZoomOut) {
          removeFocus()
        }
      },
      state => state.triggerWorldZoomOut
    )
  }, [
    camera,
    cameraFovStandard,
    setActiveQuestion,
    setTriggerWorldZoomOut,
    setFocussedObjectUrl,
    setArtefactToPanToID,
    escFunction,
  ])

  return (
    <>
      <ambientLight intensity={0.3} />
      <directionalLight
        ref={lightRef}
        castShadow={true}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        shadow-camera-near={0.1}
        shadow-camera-far={100}
        shadow-camera-left={-20}
        shadow-camera-right={20}
        shadow-camera-top={20}
        shadow-camera-bottom={-20}
        intensity={0.5}
        target={lightTargetRef.current}
      />
      <object3D ref={lightTargetRef} />
      <object3D ref={cameraTrackerRef} />
      <group rotation={[-Math.PI / 2, 0, (-7 * Math.PI) / 4]}>
        <Suspense fallback={null}>
          <Environment />
        </Suspense>
        <Suspense fallback={null}>
          <Floor />
        </Suspense>
        <Grid
          rows={grid}
          questionNumberMappings={questionNumberMappings}
          focusOn={focusOn}
        />
      </group>
      <mapControls
        ref={controlsRef}
        args={[camera, domElement]}
        enableZoom={true}
        enableRotate={false}
        enablePan={true}
        zoomSpeed={1}
        minDistance={12}
        maxDistance={17.5}
        minAzimuthAngle={0}
        maxAzimuthAngle={0}
        enableDamping
      />
      <PerspectiveCamera
        makeDefault
        fov={cameraFovStandard}
        position={[0, cameraDistVertical, cameraDistHorizontal]}
      />
    </>
  )
})

const World = ({ grid, questionNumberMappings, triggerTransition }) => {
  const setTriggerWorldZoomOut = useStore(state => state.setTriggerWorldZoomOut)
  const focussedObjectUrl = useStore(state => state.focussedObjectUrl)

  return (
    <div tw="fixed z-10 left-0 top-0 w-full h-full">
      {focussedObjectUrl ? (
        <div tw="absolute flex flex-col items-center justify-center bottom-0 w-full pb-16 z-50">
          <div
            tw="flex flex-col items-center w-48"
            css={css`
              position: relative;
              top: -5px;
            `}
          >
            <div tw="mb-4">
              <Button
                text="view artwork"
                smallForMobile={true}
                playSample={true}
                onClick={() => {
                  navigate(focussedObjectUrl)
                }}
              ></Button>
            </div>
            <Button
              text="&larr; return to world"
              smallForMobile={true}
              playSample={true}
              onClick={() => {
                setTriggerWorldZoomOut(true)
              }}
            ></Button>
          </div>
        </div>
      ) : null}
      <Canvas
        shadowMap={true}
        colorManagement={true}
        pixelRatio={window.devicePixelRatio || 1}
        gl={{ antialias: false }}
      >
        <Scene
          grid={grid}
          questionNumberMappings={questionNumberMappings}
          triggerTransition={triggerTransition}
        />
      </Canvas>
    </div>
  )
}

World.propTypes = {
  grid: PropTypes.array,
  triggerTransition: PropTypes.func,
}

export default World
