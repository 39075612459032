import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  TextureLoader,
  FrontSide,
  BackSide,
  DoubleSide,
  NearestFilter,
  RGBFormat,
  Frustum,
  Matrix4,
} from 'three'
import { VideoTexture } from '../../../VideoTexture.js'
import { useThree, useFrame, useLoader } from 'react-three-fiber'
import { Plane } from '@react-three/drei'
import gsap from 'gsap'

import { useStore } from '../../../../../store/index'

import {
  calculateTitleDimensions,
  createTitleTexture,
} from '../../../../../utilities/textures'

const ArtefactVideo = ({ id, video, title, uri, focusOn, ...props }) => {
  const ref = useRef()
  const titleCylinderFrontRef = useRef()
  const titleTextureMaterialFrontRef = useRef()
  const titleCylinderBackRef = useRef()
  const titleTextureMaterialBackRef = useRef()
  const titleTextureRef = useRef()
  const tweenValuesRef = useRef({
    titleOpacity: 0,
  })

  const focussedObjectUrl = useStore(state => state.focussedObjectUrl)

  // check for pan change
  useStore.subscribe(
    artefactToPanToID => {
      if (artefactToPanToID && artefactToPanToID.id === id && ref.current) {
        focusOn(ref.current.parent, uri)
      }
    },
    state => state.artefactToPanToID
  )

  const aspectRatio = 16 / 9
  const width = 2 * aspectRatio
  const height = 2

  const {
    titleCylinderRadius,
    titleCylinderHeight,
    titleCylinderTextureWidth,
    titleCylinderTextureHeight,
  } = calculateTitleDimensions(width, height)

  useEffect(() => {
    titleTextureRef.current = createTitleTexture(
      title,
      titleCylinderTextureWidth,
      titleCylinderTextureHeight
    )
  }, [title, titleCylinderTextureHeight, titleCylinderTextureWidth])

  useEffect(() => {
    if (focussedObjectUrl === uri) {
      gsap.to(tweenValuesRef.current, {
        titleOpacity: 1,
        duration: 1,
      })
    } else {
      gsap.to(tweenValuesRef.current, {
        titleOpacity: 0,
        duration: 1,
      })
    }
  }, [focussedObjectUrl, uri])

  const [videoInitialized, setVideoInitialized] = useState(false)
  const { camera } = useThree()

  const rotationSpeed = 0.5

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.y += rotationSpeed * delta
    }

    if (titleCylinderFrontRef.current && titleCylinderBackRef.current) {
      titleCylinderFrontRef.current.rotation.y -= rotationSpeed * delta
      titleCylinderBackRef.current.rotation.y -= rotationSpeed * delta
    }

    if (
      titleTextureMaterialFrontRef.current &&
      titleTextureMaterialBackRef.current
    ) {
      titleTextureMaterialFrontRef.current.opacity =
        tweenValuesRef.current.titleOpacity
      titleTextureMaterialBackRef.current.opacity =
        tweenValuesRef.current.titleOpacity
    }
  })

  // for some reason, we need this for artefact list pan to work correctly!
  useLoader(TextureLoader, '/dummy.png')

  // initialize video element
  const videoEl = useMemo(() => {
    const videoEl = document.createElement('video')

    videoEl.autoplay = true
    videoEl.muted = true
    videoEl.loop = true
    videoEl.crossOrigin = 'Anonymous'

    // wait for enough video data to be available
    videoEl.addEventListener(
      'loadeddata',
      () => {
        if (videoEl.readyState >= 3) {
          setVideoInitialized(true)
        }
      },
      true
    )

    videoEl.src = video.url
    videoEl.crossOrigin = 'Anonymous'

    return videoEl
  }, [video])

  // when the video is loaded, play the video and pause to get some frames showing
  useEffect(() => {
    if (videoInitialized) {
      videoEl.currentTime = 0
      videoEl.pause()

      setTimeout(() => {
        videoEl.play()
        setTimeout(() => {
          videoEl.pause()
        }, 1000)
      }, 1000)
    }
  }, [videoEl, videoInitialized])

  const texture = useMemo(() => {
    const t = new VideoTexture(videoEl)
    t.flipY = true
    t.minFilter = NearestFilter
    t.magFilter = NearestFilter
    t.format = RGBFormat
    return t
  }, [videoEl])

  /* this checks if video is in frustrum, and plays it if so */
  useEffect(() => {
    const playVideoIfInView = () => {
      // TODO: do we also need to check if there has been a user interaction?
      if (videoInitialized) {
        camera.updateMatrix()
        camera.updateMatrixWorld()
        var frustum = new Frustum()
        frustum.setFromProjectionMatrix(
          new Matrix4().multiplyMatrices(
            camera.projectionMatrix,
            camera.matrixWorldInverse
          )
        )

        if (frustum.intersectsObject(ref.current)) {
          videoEl.play()
        } else {
          videoEl.pause()
        }
      }
    }

    playVideoIfInView()
    const interval = setInterval(() => playVideoIfInView(), 200)

    return () => {
      clearInterval(interval)
    }
  }, [camera, videoEl, videoInitialized])

  return (
    <>
      {titleTextureRef.current ? (
        <>
          <mesh
            position-y={titleCylinderHeight / 3}
            ref={titleCylinderFrontRef}
            receiveShadow={false}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={FrontSide}
              map={titleTextureRef.current}
              transparent={true}
              ref={titleTextureMaterialFrontRef}
              opacity={0}
              depthTest={false}
            />
          </mesh>
          <mesh
            position-y={titleCylinderHeight / 3}
            ref={titleCylinderBackRef}
            receiveShadow={false}
          >
            <cylinderBufferGeometry
              attach="geometry"
              args={[
                titleCylinderRadius,
                titleCylinderRadius,
                titleCylinderHeight,
                24,
              ]}
            />
            <meshBasicMaterial
              attachArray="material"
              color="white"
              side={BackSide}
              map={titleTextureRef.current}
              transparent={true}
              ref={titleTextureMaterialBackRef}
              opacity={0}
            />
          </mesh>
        </>
      ) : null}

      <Plane
        ref={ref}
        castShadow={true}
        args={[width, height]}
        position-y={height / 2}
        onPointerDown={e => {
          e.stopPropagation()

          if (focusOn) {
            focusOn(e.eventObject.parent, uri)
          }
        }}
        {...props}
      >
        <meshPhongMaterial
          roughness={1}
          metalness={0.5}
          shininess={100}
          attach="material"
          map={texture}
          side={DoubleSide}
        />
      </Plane>
    </>
  )
}

ArtefactVideo.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string,
    focusOn: PropTypes.func,
  }),
}

export default ArtefactVideo
