import React from 'react'
import PropTypes from 'prop-types'

import 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialIcons = ({ icons }) => (
  <div tw="text-right">
    <div tw="mb-4 text-sm xl:text-base">
      Follow the conversation
      <br />
      #thisplaceofmine
    </div>
    <div>
      {icons.map(
        (icon, index) =>
          icon.socialAccountName &&
          icon.socialAccountUrl && (
            <a
              key={index}
              tw="no-underline mr-2 last:mr-0 focus:outline-none focus:text-brand-light-grey"
              href={icon.socialAccountUrl}
              rel="noreferrer"
              target="_blank"
              aria-label={`See the This Place of Mine conversation on ${icon.socialAccountName}`}
            >
              <FontAwesomeIcon
                transform="shrink-4"
                icon={['fab', icon.socialAccountName]}
                size="2x"
                mask={['fas', 'circle']}
              />
            </a>
          )
      )}
    </div>
  </div>
)

SocialIcons.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      socialAccountName: PropTypes.string,
      socialAccountUrl: PropTypes.string,
    })
  ),
}

SocialIcons.defaultProps = {
  icons: [],
}

export default SocialIcons
