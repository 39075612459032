import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FocusTrap from 'focus-trap-react'

import { css } from '@emotion/core'
import tw from 'twin.macro'

import { useStore } from '../store/index'

import BrandmarkCycle from './brandmark-cycle'
import SocialIcons from './social-icons'

const containerVariants = {
  hide: { x: '100%', transition: { duration: 0.4, type: 'tween' } },
  show: {
    x: 0,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.05,
      duration: 0.4,
      type: 'tween',
    },
  },
}

const itemVariants = {
  hide: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
}

const Overlay = ({
  show,
  showOverlay,
  setShowOverlay,
  socialIcons,
  children,
}) => {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: craftNavigationGlobalSet {
        body
        credits
      }
    }
  `)

  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)

  // set escape key handler
  useEffect(() => {
    const escFunction = e => {
      if (e.keyCode === 27) {
        setShowOverlay(false)
      }
    }
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [setShowOverlay])

  useEffect(() => {
    setAudioSampleTriggers(showOverlay ? 'uiOpen' : 'uiClose', true)
  }, [showOverlay, setAudioSampleTriggers])

  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <div tw="fixed inset-0 z-60">
          <FocusTrap>
            <motion.div
              tw="absolute bg-black text-white w-full md:w-2/3 lg:w-1/2 p-6 xl:p-8 right-0 inset-y-0 overflow-y-auto z-10 flex flex-col justify-between items-end"
              variants={containerVariants}
              initial="hide"
              animate="show"
              exit="hide"
              aria-modal="true"
              role="dialog"
            >
              <motion.div
                tw="w-full flex justify-between items-center"
                variants={itemVariants}
              >
                <div tw="text-lg">
                  <FontAwesomeIcon
                    tw="mr-2 relative"
                    css={css`
                      top: 1px;
                    `}
                    icon={['far', 'arrow-down']}
                  />
                  Menu
                </div>
                <button
                  tw="relative ml-4 hover:text-brand-light-grey focus:outline-none focus:text-brand-light-grey"
                  css={css`
                    top: 6px;
                    left: -6px;
                  `}
                  aria-label="Close primary navigation"
                  onClick={() => {
                    setShowOverlay(!showOverlay)
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
                </button>
              </motion.div>
              <div tw="justify-center items-center my-8">
                {React.Children.map(children, (child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
              </div>
              {(navigation.body || navigation.credits) && (
                <motion.div tw="text-right max-w-xl" variants={itemVariants}>
                  {navigation.body && (
                    <div
                      tw="text-lg xl:text-xl 2xl:text-2xl mb-8"
                      dangerouslySetInnerHTML={{
                        __html: navigation.body,
                      }}
                    ></div>
                  )}
                  {navigation.credits && (
                    <div
                      tw="text-sm xl:text-base text-brand-dark-grey mb-8"
                      dangerouslySetInnerHTML={{
                        __html: navigation.credits,
                      }}
                    ></div>
                  )}
                </motion.div>
              )}
              <motion.div
                tw="w-full mx-auto flex justify-between items-end"
                variants={itemVariants}
              >
                <div
                  tw="flex justify-center items-end w-12 h-12 xl:w-16 xl:h-16"
                  css={css`
                    svg {
                      ${tw`w-full h-auto relative`}
                      bottom: 10px;
                    }
                  `}
                >
                  <BrandmarkCycle duration={400} />
                </div>
                <SocialIcons icons={socialIcons} />
              </motion.div>
            </motion.div>
          </FocusTrap>
        </div>
      )}
    </AnimatePresence>
  )
}

Overlay.propTypes = {
  show: PropTypes.bool,
  showOverlay: PropTypes.bool,
  setShowOverlay: PropTypes.func,
  socialIcons: PropTypes.arrayOf(
    PropTypes.shape({
      socialAccountName: PropTypes.string,
      socialAccountUrl: PropTypes.string,
    })
  ),
  children: PropTypes.node,
}

Overlay.defaultProps = {
  show: false,
  showOverlay: false,
  setShowOverlay: null,
  socialIcons: [],
  children: null,
}

export default Overlay
