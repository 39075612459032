import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

import tw, { styled } from 'twin.macro'

const NavList = styled.ul`
  ${tw`text-5xl xl:text-6xl 2xl:text-7xl relative text-right`}
`

const NavListItem = styled(motion.li)`
  ${tw`relative mb-4 last:mb-0`}

  a {
    ${tw`text-white block no-underline hover:text-brand-light-grey focus:outline-none focus:text-brand-light-grey transition duration-200`}
  }
`

const listItemVisibilityVariants = {
  hide: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
}

const OverlayNav = () => {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: craftNavigationGlobalSet {
        primaryNav {
          id
          title
          uri
        }
      }
    }
  `)

  return (
    <nav tw="relative flex justify-center font-heading">
      <NavList>
        {navigation.primaryNav.map(item => (
          <NavListItem key={item.id} variants={listItemVisibilityVariants}>
            <Link to={'/' + (item.uri === '__home__' ? '' : item.uri)}>
              {item.uri === '__home__' ? 'Home' : item.title}
            </Link>
          </NavListItem>
        ))}
      </NavList>
    </nav>
  )
}

export default OverlayNav
