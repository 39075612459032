import React from 'react'
import PropTypes from 'prop-types'
import DoubleMarquee from 'react-double-marquee'

import tw, { styled } from 'twin.macro'

const StyledMarqueeContainer = styled.div`
  ${tw`text-4xl xl:text-6xl w-full whitespace-nowrap`}

  > div {
    ${tw`overflow-hidden!`}
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return tw`text-xl xl:text-2xl`
      case 'medium':
        return tw`text-2xl xl:text-4xl`
      default:
        return tw`text-4xl xl:text-6xl`
    }
  }}
`

const Marquee = ({ size, children }) => {
  return (
    <StyledMarqueeContainer size={size}>
      <DoubleMarquee direction="left" speed={0.07} delay={0}>
        {children}
      </DoubleMarquee>
    </StyledMarqueeContainer>
  )
}

export default Marquee

Marquee.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
}

Marquee.defaultProps = {
  size: 'large',
  children: null,
}
