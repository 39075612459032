import React from 'react'
import PropTypes from 'prop-types'

import QuadrantLayout from '../quadrant-layout'
import GridQuadrant from './quadrant'

const Grid = ({ rows, questionNumberMappings, focusOn }) => {
  const spacing = 7.5

  const rowCount = rows.length
  const colCount = Math.max(...rows.map(el => el.items.length))

  const gridHeight = spacing * rowCount
  const gridWidth = spacing * colCount

  /* calculate the items belonging in each quadrant,
     by dividing the rows into 2, and items in each row into 2 */
  const halfwayRowIndex = Math.floor(rows.length / 2)

  const getQuadrantRowSliceItems = (items, rowSlice) => {
    const halfwayItemIndex = Math.floor(items.length / 2)
    return {
      items:
        rowSlice === 'first'
          ? items.slice(0, halfwayItemIndex)
          : items.slice(halfwayItemIndex, items.length),
      totalRowItemCount: items.length,
    }
  }

  const quadrantData = {
    topLeft: {
      rows: rows.slice(0, halfwayRowIndex).map(row => {
        return getQuadrantRowSliceItems(row.items, 'first')
      }),
    },
    topRight: {
      rows: rows.slice(0, halfwayRowIndex).map(row => {
        return getQuadrantRowSliceItems(row.items, 'second')
      }),
    },
    bottomLeft: {
      rows: rows.slice(halfwayRowIndex, rows.length).map(row => {
        return getQuadrantRowSliceItems(row.items, 'first')
      }),
    },
    bottomRight: {
      rows: rows.slice(halfwayRowIndex, rows.length).map(row => {
        return getQuadrantRowSliceItems(row.items, 'second')
      }),
    },
  }

  return (
    <QuadrantLayout width={gridWidth} height={gridHeight}>
      <GridQuadrant
        label="topLeft"
        rows={quadrantData.topLeft.rows}
        spacing={spacing}
        totalGridColCount={colCount}
        totalGridRowCount={rowCount}
        questionNumberMappings={questionNumberMappings}
        focusOn={focusOn}
      />
      <GridQuadrant
        label="topRight"
        rows={quadrantData.topRight.rows}
        spacing={spacing}
        totalGridColCount={colCount}
        totalGridRowCount={rowCount}
        questionNumberMappings={questionNumberMappings}
        focusOn={focusOn}
      />
      <GridQuadrant
        label="bottomLeft"
        rows={quadrantData.bottomLeft.rows}
        spacing={spacing}
        totalGridColCount={colCount}
        totalGridRowCount={rowCount}
        questionNumberMappings={questionNumberMappings}
        focusOn={focusOn}
      />
      <GridQuadrant
        label="bottomRight"
        rows={quadrantData.bottomRight.rows}
        spacing={spacing}
        totalGridColCount={colCount}
        totalGridRowCount={rowCount}
        questionNumberMappings={questionNumberMappings}
        focusOn={focusOn}
      />
    </QuadrantLayout>
  )
}

Grid.propTypes = {
  rows: PropTypes.array,
  focusOn: PropTypes.func,
}

export default Grid
