import create from 'zustand'

export const useStore = create(set => ({
  isMuted: true,
  setIsMuted: isMuted =>
    set(() => ({
      isMuted,
    })),
  isAmbientAudioDucked: true,
  setIsAmbientAudioDucked: isAmbientAudioDucked =>
    set(() => ({
      isAmbientAudioDucked,
    })),
  timeInApp: 0,
  setTimeInApp: timeInApp =>
    set(() => ({
      timeInApp,
    })),
  audioSampleTriggers: {},
  setAudioSampleTriggers: (name, active) =>
    set(state => ({
      audioSampleTriggers: { ...state.audioSampleTriggers, [name]: active },
    })),
  lookingAt: {
    x: 0,
    y: 0,
  },
  setLookingAt: (x, y) =>
    set(() => ({
      lookingAt: {
        x,
        y,
      },
    })),
  focussedObjectUrl: null,
  setFocussedObjectUrl: focussedObjectUrl =>
    set(() => ({
      focussedObjectUrl,
    })),
  triggerWorldZoomOut: false,
  setTriggerWorldZoomOut: triggerWorldZoomOut =>
    set(() => ({
      triggerWorldZoomOut,
    })),

  activeQuestion: null,
  setActiveQuestion: activeQuestion =>
    set(() => ({
      activeQuestion,
    })),
  artefactToPanToID: {},
  setArtefactToPanToID: artefactToPanToID =>
    set(() => ({
      artefactToPanToID,
    })),

  questionsCompleted: [],
  setQuestionsCompleted: questionCompleted =>
    set(state => ({
      questionsCompleted: [...state.questionsCompleted, questionCompleted],
    })),
}))
