import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { globalHistory } from '@reach/router'
import { Transition } from 'react-transition-group'
import { extend } from 'react-three-fiber'
import { CookiesProvider } from 'react-cookie'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

import { faArrowDown, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { Global, css } from '@emotion/core'
import tw from 'twin.macro'

import { isBrowser } from '../utilities/browser'

import SiteHead from '../components/site-head'
import Overlay from '../components/overlay'
import OverlayNav from '../components/overlay-nav'
import Intro from '../components/intro'
import Feed from '../components/feed'
import Questions from '../components/questions'
import ArtefactList from '../components/artefact-list'
import World from '../components/world'
import AmbientAudio from '../components/audio/ambient'
import AmbientSamples from '../components/audio/samples'
import Stats from '../components/stats'

import { useStore } from '../store/index'

import { MapControls } from '../components/world/OrbitControls'

import WorldPlaceholderImg from '../images/world-placeholder.jpg'

import { inTransition } from '../transitions/fade'

library.add(
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faTimes,
  faArrowDown,
  faArrowRight
)

const opacityTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const Layout = ({ children, pageContext }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [showIntro, setShowIntro] = useState(true)
  const [worldShouldInitialize, setWorldShouldInitialize] = useState(
    pageContext.layout === 'home'
  )

  const setIsAmbientAudioDucked = useStore(
    state => state.setIsAmbientAudioDucked
  )

  extend({ MapControls })

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setShowOverlay(false)
    })
  }, [])

  // if we're moving to the home layout for the first time, initialize the world
  useEffect(() => {
    if (pageContext.layout === 'home') {
      setWorldShouldInitialize(true)
      setIsAmbientAudioDucked(false)
    } else if (
      pageContext.layout === 'single' ||
      pageContext.layout === 'channel'
    ) {
      setIsAmbientAudioDucked(true)
    }
  }, [pageContext.layout, setIsAmbientAudioDucked])

  const triggerTransition = useTriggerTransition({
    inTransition: false,
    exit: {
      length: 0,
    },
    entry: {
      length: 2,
      appearAfter: 0.25,
      trigger: ({ node }) => inTransition({ node }),
    },
  })

  const getSortedArtefactsFromGrid = gridItems => {
    const youngProducerTypes = [
      'artefactImage',
      'artefactVideo',
      'artefactAudio',
      'artefactObject',
    ]
    const citizenFuturistTypes = [
      'artefactImageCf',
      'artefactVideoCf',
      'artefactAudioCf',
      'artefactObjectCf',
    ]
    const artefacts = gridItems
      .map(row => {
        return row.items.map(item => {
          const isYp = youngProducerTypes.includes(item.typeHandle)
          const isCf = citizenFuturistTypes.includes(item.typeHandle)
          let artistName = ''
          let artistType = ''
          let artworkType = ''

          if (isYp) {
            artistType = 'Young Producer'

            if (item.youngProducer.length) {
              artistName = item.youngProducer[0].title
            }

            artworkType = item.artworkType
          } else if (isCf) {
            artistType = 'Citizen Futurist'

            if (item.artwork.length && item.artwork[0].citizenFuturist.length) {
              artistName = item.artwork[0].citizenFuturist[0].title
            }

            if (item.artwork.length) {
              artworkType = item.artwork[0].artworkType
            }
          }

          return {
            id: item.id,
            title: item.title,
            uri: item.uri,
            typeHandle: item.typeHandle,
            artistType,
            artistName,
            artworkType,
          }
        })
      })
      .flat(1)
      .filter(item =>
        [
          'artefactImage',
          'artefactVideo',
          'artefactObject',
          'artefactAudio',
          'artefactImageCf',
          'artefactVideoCf',
          'artefactObjectCf',
          'artefactAudioCf',
        ].includes(item.typeHandle)
      )

    return artefacts.sort((a, b) =>
      a.title > b.title ? 1 : b.title > a.title ? -1 : 0
    )
  }

  const { homeEntry, social, intro, legals } = useStaticQuery(graphql`
    {
      homeEntry: craftHomeCfHomeCfEntry {
        worldGrid {
          ...WorldGrid
        }
        liveFeedItems {
          ... on Craft_feedItem_feedItem_Entry {
            itemText
          }
        }
        liveFeedIntroItems {
          ... on Craft_feedItem_feedItem_Entry {
            itemText
          }
        }
        questionsIntro
        questionsOutro
        questionAnswered
        question1 {
          ...Question
        }
        question2 {
          ...Question
        }
        question3 {
          ...Question
        }
        question4 {
          ...Question
        }
        question5 {
          ...Question
        }
        question6 {
          ...Question
        }
        artefactListIntro
      }
      social: craftSocialGlobalSet {
        socialIcons {
          ... on Craft_socialIcons_socialAccount_BlockType {
            socialAccountName
            socialAccountUrl
            id
          }
        }
      }
      intro: craftIntroGlobalSet {
        intro
      }
      legals: craftLegalsGlobalSet {
        cookiePolicy
      }
    }
  `)

  // map question numbers to question IDs
  const questionNumberMappings = {}
  questionNumberMappings[1] = homeEntry.question1.length
    ? homeEntry.question1[0].id
    : null
  questionNumberMappings[2] = homeEntry.question2.length
    ? homeEntry.question2[0].id
    : null
  questionNumberMappings[3] = homeEntry.question3.length
    ? homeEntry.question3[0].id
    : null
  questionNumberMappings[4] = homeEntry.question4.length
    ? homeEntry.question4[0].id
    : null
  questionNumberMappings[5] = homeEntry.question5.length
    ? homeEntry.question5[0].id
    : null
  questionNumberMappings[6] = homeEntry.question6.length
    ? homeEntry.question6[0].id
    : null

  const renderSwitch = () => {
    switch (pageContext.layout) {
      case '404':
        return <>{children}</>
      default:
        return (
          <main tw="w-full h-full relative font-body font-bold">
            {worldShouldInitialize && (
              <Transition in={showIntro} timeout={1250} unmountOnExit={true}>
                {state => {
                  return (
                    <Intro
                      body={intro.intro}
                      cookiePolicy={legals.cookiePolicy}
                      style={{ ...opacityTransitionStyles[state] }}
                      setShowIntro={setShowIntro}
                      disablePointerEvents={
                        state === 'exiting' || state === 'exited'
                      }
                    />
                  )
                }}
              </Transition>
            )}
            <AmbientSamples />
            <Overlay
              show={showOverlay}
              showOverlay={showOverlay}
              setShowOverlay={setShowOverlay}
              socialIcons={social.socialIcons}
            >
              <div tw="h-full w-full flex justify-center items-center">
                <OverlayNav />
              </div>
            </Overlay>
            <SiteHead
              show={true}
              showBg={false}
              reverseTextColour={pageContext.layout === 'single'}
              showOverlay={showOverlay}
              setShowOverlay={setShowOverlay}
              isOnSinglePage={pageContext.layout === 'single'}
            />
            <Feed
              items={homeEntry.liveFeedItems}
              introItems={homeEntry.liveFeedIntroItems}
              isActive={!showIntro}
            />
            <Questions
              intro={homeEntry.questionsIntro}
              outro={homeEntry.questionsOutro}
              questionAnsweredText={homeEntry.questionAnswered}
              questionNumberMappings={questionNumberMappings}
            />
            <ArtefactList
              intro={homeEntry.artefactListIntro}
              artefacts={getSortedArtefactsFromGrid(homeEntry.worldGrid)}
            />
            <div>
              {isBrowser &&
                (worldShouldInitialize ? (
                  <World
                    grid={homeEntry.worldGrid}
                    questionNumberMappings={questionNumberMappings}
                    triggerTransition={triggerTransition}
                  />
                ) : (
                  // we only need to show this placeholder bg if its an internal channel page
                  pageContext.layout === 'channel' && (
                    <img
                      width="1920"
                      height="1080"
                      src={WorldPlaceholderImg}
                      sizes="100vw"
                      tw="fixed left-0 top-0 w-full h-full object-cover"
                      alt=""
                    />
                  )
                ))}
            </div>
            <div
              tw="fixed"
              style={{ zIndex: pageContext.layout === 'single' ? 30 : 50 }}
            >
              <Transition
                appear={false}
                in={pageContext.layout === 'single'}
                timeout={1000}
                unmountOnExit={true}
              >
                {state => (
                  <div
                    tw="fixed inset-0 z-40 top-0 bg-brand-light-grey opacity-0 transition-opacity duration-1000"
                    style={{ ...opacityTransitionStyles[state] }}
                  ></div>
                )}
              </Transition>
              <div tw="relative z-50">{children}</div>
            </div>
          </main>
        )
    }
  }

  return (
    <CookiesProvider>
      <AmbientAudio />
      <Stats />
      <Global
        styles={css`
          html,
          body,
          #___gatsby,
          #___gatsby > * {
            ${tw`lg:h-full!`}
          }
          html {
            p {
              ${tw`mb-4`}

              &:last-child {
                ${tw`mb-0`}
              }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              ${tw`text-lg mb-4`}
            }
          }

          body {
            overscroll-behavior: contain;
          }

          a {
            ${tw`underline hover:no-underline`}
          }
        `}
      />
      {renderSwitch()}
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

Layout.defaultProps = {
  children: null,
}

export default Layout
