import React, { useRef, Suspense } from 'react'
import PropTypes from 'prop-types'

import ArtefactImage from './artefact/image'
import ArtefactVideo from './artefact/video'
import Artefact3D from './artefact/3d'
import Brandmark from './brandmark/index'
import Question from './question/index'

import { checkIfiOS } from '../../../../utilities/browser'

const GridItem = ({
  item,
  focusOn,
  posX,
  posY,
  questionNumberMappings,
  ...props
}) => {
  const randomness = 0

  const ref = useRef()
  const offsetX = useRef(Math.random() * randomness - randomness / 2)
  const offsetY = useRef(Math.random() * randomness - randomness / 2)

  const renderSwitch = () => {
    switch (item.typeHandle) {
      case 'artefactImage':
        return item.imageAsset.length ? (
          <Suspense fallback={null}>
            <ArtefactImage
              id={item.id}
              title={item.title}
              image={item.imageAsset[0]}
              uri={item.uri}
              focusOn={focusOn}
              {...props}
            />
          </Suspense>
        ) : null
      case 'artefactImageCf':
        return item.imageAsset.length && item.artwork.length ? (
          <Suspense fallback={null}>
            <ArtefactImage
              id={item.id}
              title={item.title}
              image={item.imageAsset[0]}
              uri={item.artwork[0].uri}
              focusOn={focusOn}
              {...props}
            />
          </Suspense>
        ) : null
      case 'artefactVideo':
        let obj = null
        if (checkIfiOS()) {
          obj = item.imageAsset.length ? (
            <Suspense fallback={null}>
              <ArtefactImage
                id={item.id}
                title={item.title}
                image={item.imageAsset[0]}
                uri={item.uri}
                focusOn={focusOn}
                {...props}
              />
            </Suspense>
          ) : null
        } else {
          obj = item.videoAssetMp4.length ? (
            <Suspense fallback={null}>
              <ArtefactVideo
                id={item.id}
                title={item.title}
                video={item.videoAssetMp4[0]}
                uri={item.uri}
                focusOn={focusOn}
                {...props}
              />
            </Suspense>
          ) : null
        }
        return obj
      case 'artefactVideoCf':
        let obj2 = null
        if (checkIfiOS()) {
          obj2 =
            item.imageAsset.length && item.artwork.length ? (
              <Suspense fallback={null}>
                <ArtefactImage
                  id={item.id}
                  title={item.title}
                  image={item.imageAsset[0]}
                  uri={item.artwork[0].uri}
                  focusOn={focusOn}
                  {...props}
                />
              </Suspense>
            ) : null
        } else {
          obj2 =
            item.videoAssetMp4.length && item.artwork.length ? (
              <Suspense fallback={null}>
                <ArtefactVideo
                  id={item.id}
                  title={item.title}
                  video={item.videoAssetMp4[0]}
                  uri={item.artwork[0].uri}
                  focusOn={focusOn}
                  {...props}
                />
              </Suspense>
            ) : null
        }
        return obj2
      case 'artefactObject':
        return item.threeDAssetGltf.length ? (
          <Suspense fallback={null}>
            <Artefact3D
              id={item.id}
              title={item.title}
              object={item.threeDAssetGltf[0]}
              scaleFactor={item.scaleFactor}
              verticalOffset={item.verticalOffset}
              rotation={item.rotation}
              uri={item.uri}
              focusOn={focusOn}
              {...props}
            />
          </Suspense>
        ) : null
      case 'artefactObjectCf':
        return item.threeDAssetGltf.length && item.artwork.length ? (
          <Suspense fallback={null}>
            <Artefact3D
              id={item.id}
              title={item.title}
              object={item.threeDAssetGltf[0]}
              scaleFactor={item.scaleFactor}
              verticalOffset={item.verticalOffset}
              rotation={item.rotation}
              uri={item.artwork[0].uri}
              focusOn={focusOn}
              {...props}
            />
          </Suspense>
        ) : null
      case 'question':
        return (
          <Suspense fallback={null}>
            <Question
              id={item.id}
              question={item}
              focusOn={focusOn}
              questionNumberMappings={questionNumberMappings}
              {...props}
            />
          </Suspense>
        )
      case 'brandmark':
        return item.imageAsset.length ? (
          <Suspense fallback={null}>
            <Brandmark image={item.imageAsset[0]} {...props} />
          </Suspense>
        ) : null
      case 'decoration':
        return item.threeDAssetGltf.length ? (
          <Suspense fallback={null}>
            <Artefact3D
              id={item.id}
              object={item.threeDAssetGltf[0]}
              scaleFactor={item.scaleFactor}
              verticalOffset={item.verticalOffset}
              rotation={item.rotation}
              {...props}
            />
          </Suspense>
        ) : null
      default:
        return null
    }
  }

  return (
    <group
      position-x={posX + offsetX.current}
      position-y={posY + offsetY.current}
      rotation={[Math.PI / 2, 0, 0]}
      ref={ref}
    >
      {renderSwitch()}
    </group>
  )
}

GridItem.propTypes = {
  item: PropTypes.object,
  focusOn: PropTypes.func,
}

export default GridItem
