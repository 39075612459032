import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import TypeIt from 'typeit-react'
import useStateRef from 'react-usestateref'
import Scrollbar from 'smooth-scrollbar'

import { css } from '@emotion/core'
import tw from 'twin.macro'

import { useRandomInterval } from '../hooks/useRandomInterval'

import { useStore } from '../store/index'

import SidePanel from './side-panel'

import FeedIconImg from '../svgs/feed-icon.svg'

const Feed = ({ items, introItems, isActive }) => {
  const rangeBetweenFeedOutput = [60000, 90000]
  const rangeBetweenDynamicStringInsertion = [150000, 300000]

  const [numberOfFeedItems, setNumberOfFeedItems] = useState(items.length)
  const [dynamicStrings, setDynamicStrings] = useState([
    `Having fun? You've been hanging out here for TIMEINAPP.`,
    'QUESTIONSREMAINING',
  ])

  const itemsRef = useRef(items)
  const typeInstance = useRef(null)
  const setAudioSampleTriggers = useStore(state => state.setAudioSampleTriggers)
  const questionsCompleted = useStore(state => state.questionsCompleted)

  const timeGreetingRef = useRef(null)
  const timeInAppRef = useRef(useStore.getState().timeInApp)

  useEffect(
    () =>
      useStore.subscribe(
        timeInApp => (timeInAppRef.current = timeInApp),
        state => state.timeInApp
      ),
    []
  )

  const scrollableElRef = useCallback(node => {
    if (node !== null) {
      Scrollbar.init(node)
    } else {
      Scrollbar.destroyAll()
    }
  }, [])

  // we need to access this state in a ref because of the fixed beforeString + afterString callbacks
  const [isOpen, setIsOpen, isOpenRef] = useStateRef(false)
  const [numberToShow, setNumberToShow, numberToShowRef] = useStateRef(1)
  const [numberShown, setNumberShown, numberShownRef] = useStateRef(0)

  useEffect(() => {
    const getTimeGreeting = () => {
      const today = new Date()
      const hour = today.getHours()

      if (hour > 4 && hour < 12) {
        return 'Good morning'
      } else if (hour >= 12 && hour < 18) {
        return 'Good afternoon'
      } else {
        return 'Good evening'
      }
    }

    timeGreetingRef.current = getTimeGreeting()
  }, [])

  // store number of feed items to add to later
  useEffect(() => {
    setNumberOfFeedItems(items.length)
  }, [items.length])

  // trigger a new feed item to appear
  useRandomInterval(() => {
    if (isActive && numberToShow < numberOfFeedItems) {
      setNumberToShow(numberToShow + 1)
    }
  }, ...rangeBetweenFeedOutput)

  // insert a dynamic feed item after the first item has been displayed
  useRandomInterval(() => {
    if (dynamicStrings.length && numberShown > 1) {
      const randomSelectedString =
        dynamicStrings[Math.floor(Math.random() * dynamicStrings.length)]

      const dynamicStringReplacements = generateDynamicStringReplacements

      addNewStringToFeed(
        replaceAllStrings(randomSelectedString, dynamicStringReplacements())
      )

      setDynamicStrings(
        dynamicStrings.filter(item => item !== randomSelectedString)
      )
    }
  }, ...rangeBetweenDynamicStringInsertion)

  // unfreeze if we're open and have some to show
  useEffect(() => {
    if (
      isOpen &&
      typeInstance.current.is('frozen') &&
      numberShown < numberToShow
    ) {
      typeInstance.current.unfreeze()
    }
  }, [isOpen, numberToShow, numberShown])

  // add a random intro message to the feed
  useEffect(() => {
    // randomize output
    itemsRef.current = items
      .map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)

    itemsRef.current.unshift(
      introItems[Math.floor(Math.random() * introItems.length)]
    )
  }, [introItems, items])

  useEffect(() => {
    setAudioSampleTriggers(isOpen ? 'uiOpen' : 'uiClose', true)
  }, [isOpen, setAudioSampleTriggers])

  const addNewStringToFeed = string => {
    typeInstance.current.addNewString(string)
    setNumberOfFeedItems(numberOfFeedItems + 1)
  }

  const numberInWords = number => {
    const first = [
      '',
      'one ',
      'two ',
      'three ',
      'four ',
      'five ',
      'six ',
      'seven ',
      'eight ',
      'nine ',
      'ten ',
      'eleven ',
      'twelve ',
      'thirteen ',
      'fourteen ',
      'fifteen ',
      'sixteen ',
      'seventeen ',
      'eighteen ',
      'nineteen ',
    ]
    const tens = [
      '',
      '',
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety',
    ]
    const mad = ['', 'thousand', 'million', 'billion', 'trillion']
    let word = ''

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i))
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            ' ' +
            word
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            '-' +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            ' ' +
            word
        }
      }

      tempNumber = number % Math.pow(1000, i + 1)
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          'hundred ' +
          word
    }
    return word
  }

  const generateDynamicStringReplacements = () => {
    let questionString = `Good work - you've completed ${numberInWords(
      questionsCompleted.length
    )} ${
      questionsCompleted.length === 1 ? 'Future Fragment' : 'Future Fragments'
    }. Can you find the rest and let us know your thoughts?`
    if (questionsCompleted.length === 0) {
      questionString = `Stumbled across any Future Fragments yet? There are six to find... go explore!`
    }
    if (questionsCompleted.length === 6) {
      questionString = `Great work in finding and completing all of the Future Fragments. Thank you for letting us know your thoughts!`
    }

    return {
      TIMEINAPP: `about ${numberInWords(
        Math.round(timeInAppRef.current / 60)
      )} minutes`,
      QUESTIONSREMAINING: questionString,
    }
  }

  function replaceAllStrings(str, obj) {
    const pattern = new RegExp(Object.keys(obj).join('|'), 'g')

    return str.replace(pattern, matched => {
      return obj[matched]
    })
  }

  // decrement notification count when typing starts
  const beforeString = () => {
    setNumberShown(numberShownRef.current + 1)
  }

  // freeze if not open or we've shown all
  const afterString = () => {
    if (
      !isOpenRef.current ||
      numberShownRef.current >= numberToShowRef.current
    ) {
      typeInstance.current.freeze()
    }
  }

  return (
    <SidePanel
      heading="TPOM Transmissions"
      side="left"
      icon={<FeedIconImg></FeedIconImg>}
      notificationCount={isOpen ? 0 : numberToShow - numberShown}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div
        tw="text-base md:text-sm lg:text-xl w-full relative"
        ref={scrollableElRef}
        css={css`
          margin-top: 56px;
          height: calc(100% - 56px);
          .scrollbar-track {
            ${tw`bg-transparent!`}
          }
          .scrollbar-thumb {
            ${tw`bg-white! rounded-full!`}
          }

          @media (max-height: 900px) {
            ${tw`text-sm!`}
          }

          .ti-cursor {
            ${tw`text-brand-blue!`}
          }
        `}
      >
        <div tw="pl-12 pr-8 py-8 w-full h-full relative">
          <TypeIt
            element={'div'}
            options={{
              waitUntilVisible: true,
              speed: 30,
              strings: itemsRef.current.map(item =>
                item.itemText.replace(
                  '{TIME_GREETING}',
                  timeGreetingRef.current
                )
              ),
              beforeString,
              afterString,
            }}
            getAfterInit={instance => {
              typeInstance.current = instance
              return instance
            }}
          ></TypeIt>
        </div>
      </div>
    </SidePanel>
  )
}

Feed.propTypes = {
  items: PropTypes.array,
  introItems: PropTypes.array,
  isActive: PropTypes.bool,
}

Feed.defaultProps = {
  items: [],
  introItems: [],
  isActive: false,
}

export default Feed
